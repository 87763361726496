import React from "react";
import { Container, Typography, Card, CardContent, Grid } from "@mui/material";

const contacts = [
  { department: "Town Planner", name: "Jitendra Shelke", phone: "9922777116" },
  { department: "Fire", name: "Shankar Misal", phone: "9561004637" },
  { department: "Fire", name: "Ashok Karle", phone: "9923805566" },
  { department: "MSEB", name: "Yogesh Chavan", phone: "8956483491" },
  { department: "MSEB", name: "Swapnil Ulhe", phone: "8956491145" },
  { department: "MSEB", name: "Firoz Shaikh", phone: "9130107097" },
  { department: "Traffic", name: "Babasaheb Borase", phone: "7972542270" },
  { department: "Traffic", name: "Khamkar", phone: "9075567274" },
  {
    department: "Police Station (Kotwali)",
    name: "Devendra Pandharkar",
    phone: "9850807244",
  },
  {
    department: "Police Station (Tofkhana)",
    name: "Tanveer Shaikh",
    phone: "9730308054",
  },
  { department: "Police Station (MIDC)", name: "Sherkar", phone: "9881095527" },
  {
    department: "Police Station (Bhingar)",
    name: "Ajay Gavhane",
    phone: "9822340640",
  },
  {
    department: "Police Station (Taluka)",
    name: "Vadhane",
    phone: "9370923592",
  },
  {
    department: "Technical Support",
    name: "Gauri Birari",
    phone: "9371001138",
  },
  {
    department: "Technical Support",
    name: "Sakshi Kadave",
    phone: "96890 24172",
  },
];

const Contact = () => {
  return (
    <>
      {/* <Typography variant="h4" gutterBottom align="center"> */}
      <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          Contact Information
        </h3>
      </div>{" "}
      {/* </Typography> */}
      <Container className="mt-3">
        {/* <hr /> */}

        <Grid container spacing={3}>
          {contacts.map((contact, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{contact.department}</Typography>
                  <Typography variant="subtitle1">{contact.name}</Typography>
                  <Typography variant="body1">{contact.phone}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Contact;
