import React from "react";
import DivisionaOfficeSidebar from "../../pages/divisionalofficer/DivisionaOfficeSidebar";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
// import { Outlet } from "react-router-dom";

const DivisionalOfficerContainer = () => {
  document.title = "Divisional Officer";
  const divisionofficer = useSelector((store) => store.divisionofficer);

  return (
    <>
      <div className="row">
        <DivisionaOfficeSidebar role="Division Officer" />
        <div className="container">
          {divisionofficer ? <Outlet /> : <Navigate to="/divisional-user" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default DivisionalOfficerContainer;
