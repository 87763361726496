import { removeNagarUserToken } from "../../common";
import { ngrrachanaTokenName, } from "../../config";

// const getStudentFromLocalStorage = () => {
//   try {
//     const studentJSON = getStudentTokenFromLocal();
//     if (studentJSON) {
//       const student = JSON.parse(studentJSON);
//       if (student.authToken && student.loggedInTime) {
//         return student;
//       } else {
//         removeStudentTokenFromLocal();
//         return null;
//       }
//     }
//   } catch (e) {
//     removeStudentTokenFromLocal();
//     return null;
//   }
// };

const getPoliceFromLocalStorage = () => {
    try {
        const nagaruser = JSON.parse(sessionStorage.getItem(ngrrachanaTokenName));
        if (nagaruser && nagaruser.authToken && nagaruser.loggedInTime) {
            return nagaruser
        } else {
            removeNagarUserToken();
            return null
        }
    } catch (error) {
        removeNagarUserToken()
        return null
    }
}

const getInitial = () => {
    return getPoliceFromLocalStorage();
};

const nagarUserReducers = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_NAGAR_USER":
            const { loggedInTime, authToken } = action.payload;
            const newState = {
                loggedInTime: loggedInTime || Date.now(),
                authToken: authToken,
            };
            sessionStorage.setItem(ngrrachanaTokenName, JSON.stringify(newState));
            return newState;
        case "LOGOUT_NAGAR_USER":
            removeNagarUserToken();
            return null;
        default:
            return state;
    }
};

export default nagarUserReducers;