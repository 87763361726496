import React from "react";
import Sidebar from "../../pages/superadmin/SuperAdminSidebar";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
// import { Outlet } from "react-router-dom";

const SuperAdminContainer = () => {
  document.title = "Super Admin";
  const superadmin = useSelector((store) => store.superadmin);

  return (
    <>
      <div className="row ">
        <Sidebar role="Super Admin" />
        <div className="container">
          {superadmin ? <Outlet /> : <Navigate to="/super-admin" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default SuperAdminContainer;
