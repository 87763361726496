export const logintraffic = (session) => {
    return {
        type: "LOGIN_TRAFFIC",
        payload: session,
    };
};
export const logoutTraffic = () => {
    return {
        type: "LOGOUT_TRAFFIC",
    };
};
