// import React, { useEffect, useState } from "react";
// import { Container, Row, Col, Image, ListGroup, Button } from "react-bootstrap";
// import { server } from "../common";
// import { AxiosError } from "axios";
// import { toast } from "react-toastify";
// import { useParams } from "react-router-dom";

// const FinalPermission = () => {
//   const [data, setData] = useState([]);
//   const { id } = useParams();

//   useEffect(() => {
//     getData(id);
//   }, [id]);

//   const getData = () => {
//     server
//       .get(`/user_appl/userapplication/${id}`, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then((response) => {
//         console.log("API response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch((error) => {
//         if (error instanceof AxiosError && error.response?.data?.message)
//           toast.error(error.response.data.message);
//         else {
//           toast.error(error.response?.data?.error || "An error occurred");
//         }
//       });
//   };

//   const handlePrint = () => {
//     window.print();
//   };

//   return (
//     <>
//       {data.permission_status === "APPROVED" ? (
//         <>
//           <div className="container">
//             <Row className="justify-content-end mb-3">
//               <Col xs="auto">
//                 <Button onClick={handlePrint} variant="primary">
//                   Print
//                 </Button>
//               </Col>
//             </Row>
//           </div>
//           <div className="card mt-4 m-2 p-1 ">
//             <Row className="justify-content-center">
//               <Col xs={2} className="text-center  align-self-center">
//                 <Image src="/logo.png" className="img-fluid" />
//               </Col>
//               <Col xs={6} className="text-center align-self-center">
//                 <h3>अहमदनगर महानगरपालिका, अहमदनगर</h3>
//                 <p>(नगररचना व अतिक्रमण निर्मूलन विभाग)</p>
//                 <div>
//                   Website :
//                   <a href="https://amcfest.in/" target="_blank">
//                     https://amcfest.in/
//                   </a>{" "}
//                   &nbsp; E-mail :{" "}
//                   <a href="mailto:amc_anr@rediffmail.com">
//                     amc_anr@rediffmail.com
//                   </a>
//                 </div>

//                 <div>it.nagar@amc.gov.in</div>
//               </Col>
//               <Col xs={2} className="text-center  align-self-center">
//                 <Image src="/bharat.jpeg" className="img-fluid" />
//               </Col>
//             </Row>

//             <hr />

//             <Row className="mb-4">
//               <Col>
//                 <div className="row ">
//                   <div className="col-md-7">
//                     <p>Permission No. - {data.id}</p>
//                   </div>
//                   <div className="col-md-5 ">
//                     दिनांक: {new Date().toLocaleDateString()}
//                   </div>
//                 </div>
//               </Col>
//             </Row>

//             <Row className="mb-4 ms-3">
//               <Col>
//                 <p>
//                   प्रति, <br /> श्री. {data.name} <br /> {data.mandal_name}
//                   &nbsp;
//                   {data.mandal_address}
//                 </p>
//               </Col>
//             </Row>

//             <Row className="ms-5">
//               <Col>
//                 <p>
//                   विषय: {data.festival_name} निमित्त सार्वजनिक जागेवर तात्पुरता
//                   मंडप उभारण्यास परवानगी मिळणे बाबत.
//                 </p>
//               </Col>
//             </Row>

//             <Row className="mb-4 ms-5">
//               <Col>
//                 <p>
//                   संदर्भ: आपला दि. {data.created_at} रोजीचा अर्ज उपरोक्त
//                   संदर्भीय अर्जानुसार कळविण्यांत येते की, {data.festival_name}{" "}
//                   निमित्त मंडप उभारण्यास आपण परवानगी मागितलेली आहे.
//                   <br />
//                   <div className="mt-3">
//                     <strong>महोदय,</strong>
//                     <br />
//                     आपल्या विनंतीनुसार {data.mandap_address} या ठिकाणी दि.{" "}
//                     {data.mandap_startdate} ते {data.mandap_remove_date} या
//                     कालावधीत &nbsp;
//                     {data.mandap_length} x {data.mandap_width} या मोजमापाचे मंडप
//                     खालील अटी व शर्तीं अधिन राहून परवानगी देण्यांत येत आहे:
//                   </div>
//                 </p>
//               </Col>
//             </Row>

//             <Row>
//               <Col>
//                 <ul>
//                   <li>
//                     मा. शासनाच्या मार्गदर्शक सुचनांचे काटेकोरपणे पालन करण्यांत
//                     यावे.
//                   </li>
//                   <li>
//                     विविध संसर्गजन्य आजाराचे गांभीर्य लक्षात घेता मंडपात गर्दी
//                     होणार नाही याची दक्षता घेण्यात यावी.
//                   </li>
//                   <li>
//                     मंडपामध्ये निर्जतुकीकरणाची पर्याप्त व्यवस्था करण्यांत यावी.
//                   </li>
//                   <li>
//                     दिलेल्या परवानगीपेक्षा जास्त मोजमापाचा मंडप उभारल्यास
//                     कायदेशीर कारवाई करण्यांत येईल.
//                   </li>
//                   <li>
//                     सार्वजनिक ठिकाणी मंडप उभारताना स्थानिक रहिवाशांना अडथळा व
//                     उपद्रव निर्माण होणार नाही याची काळजी घ्यावी.
//                   </li>
//                   <li>
//                     सार्वजनिक रस्त्यावरील वाहतुकीस व नागरिकांना अडथळा होणार नाही
//                     याची खबरदारी घेण्यात यावी.
//                   </li>
//                   <li>
//                     सार्वजनिक जागेवर मंडप उभारताना रस्त्याचा किमान 60% व त्या
//                     पेक्षा जास्त भाग वाहतुकीस खुला राहील याची खबरदारी घेण्यात
//                     यावी.
//                   </li>
//                   <li>
//                     सार्वजनिक रस्त्यावर मंडप उभारताना अँम्बुलंस, अग्निशमन बंब
//                     यांना जाण्यासाठी रस्ता खुला ठेवणे आणि मंडपाच्या ठिकाणी
//                     अग्निशमन व्यवस्था ठेवणे बंधनकारक आहे.
//                   </li>
//                   <li>
//                     म.न.पा च्या सार्वजनिक मालमत्तेचे नुकसान झाल्यास दंडात्मक
//                     कारवाई करुन ते वसूल करण्यात येईल.
//                   </li>
//                   <li>
//                     कोणत्याही प्रकारे सार्वजनिक शांतता भंग होणार नाही याची
//                     दक्षता घेण्यात यावी.
//                   </li>
//                   <li>
//                     उत्सवाच्या काळात जिवित वा वित्तहानी झाल्यास जबाबदार धरुन
//                     कायदेशीर कारवाई करण्यात येईल.
//                   </li>

//                   <li>
//                     धर्मदाय आयुक्त यांच्या कडून मंडळाची/ट्रस्टची नोंदणी बंधनकारक
//                     आहे.
//                   </li>
//                   <li>
//                     मा. उच्च न्यायालय मुंबई येथील आदेशाचे पालन बंधनकारक आहे.
//                   </li>
//                   <li>
//                     दिलेल्या परवानगीचा तपशील मंडपाच्या दर्शनी भागावर ठळकपणे
//                     लावण्यात यावा.
//                   </li>
//                   <li>
//                     मिरवणुक मार्गावरील मंडप, आरास, देखावे, विद्युत रोषणाई
//                     यांमुळे कोणताही अडथळा होणार नाही याची काळजी घेण्यात यावी.
//                   </li>
//                   <li>
//                     मंडपात व परिसरातील रस्ते छायाचित्रीकरण होईल अशा प्रकारे
//                     किमान चार सीसीटीव्ही कॅमेरे बसवणे व ते अहोरात्र सुरु ठेवणे
//                     बंधनकारक आहे.
//                   </li>
//                   <li>
//                     पोलीस प्रशासनाच्या उत्सव मार्गदर्शक सुचनांचे काटेकोरपणे पालन
//                     करण्यांत यावे.
//                   </li>
//                   <li>
//                     महावितरण कंपनीकडून अधिकृत तात्पुरती वीज जोडणी घेणे बंधनकारक
//                     आहे.
//                   </li>
//                   <li>
//                     कोणत्याही प्रकारे प्रक्षोभक घोषवाक्य किंवा देखावे सादर करुन
//                     कोणाच्याही भावना दुखावल्या जाणार नाहीत याची दक्षता घेण्यात
//                     यावी.
//                   </li>
//                   <li>
//                     महिलांसाठी स्वतंत्र व्यवस्था करणे आणि छेडछाड होणार नाही याची
//                     दक्षता घेऊन स्वयंसेवक नेमण्यात यावेत.
//                   </li>
//                   <li>
//                     ध्वनीक्षेपक वापरावरील निर्बंधांचे पालन करणे बंधनकारक आहे.
//                   </li>
//                   <li>
//                     मिरवणुकीत वापरण्यात येणारी वाहने सुस्थितीत आहेत याची
//                     आरटीओकडून तपासणी करुन घेण्यात यावी.
//                   </li>
//                   <li>
//                     वाहन चालकास मद्यपान केलेले नसावे आणि लायसन्स असणे बंधनकारक
//                     आहे.
//                   </li>
//                   <li>
//                     स्थापना व विसर्जन मिरवणुकांचे व्हिडिओ शुटिंग करुन ठेवावे.
//                   </li>
//                   <li>
//                     मंडपात कोणत्याही प्रकारच्या बँग पिशव्या आणण्यास प्रतिबंध
//                     करावा आणि संशयास्पद वस्तू आढळल्यास तात्काळ पोलीसांना कळवावे.
//                   </li>
//                   <li>
//                     गणेश मूर्तीचे संरक्षणाकरीता 24 तास स्वयंसेवकांची नियुक्ती
//                     करण्यात यावी.
//                   </li>
//                   <li>
//                     मंडळाने संपूर्ण परिसर कव्हर होईल अशा प्रकारे सीसीटीव्ही
//                     बसवावे.
//                   </li>
//                   <li>विनापरवाना वर्गणी गोळा करणे बंधनकारक नाही.</li>
//                   <li>अनधिकृत फ्लेक्स, जाहिराती फलक लावू नयेत.</li>
//                   <li>
//                     धोकादायक विहीर अथवा जलस्त्रोत या ठिकाणी गणेश विसर्जन करू
//                     नये.
//                   </li>
//                   <li>
//                     कोणत्याही अटी वा शर्तींचे उल्लंघन केल्यास दिलेला परवाना रद्द
//                     करण्यात येईल.
//                   </li>
//                   <p>
//                     वरील अटी व शर्तींचे पालन न झाल्यास दिलेला परवाना त्वरीत रद्द
//                     करण्यात येईल.
//                   </p>
//                 </ul>
//               </Col>

//               <div className="text-center float-right ms-5">
//                 <img src="/esign.png" alt="" width={150} />
//                 <p>
//                   जितेंद्र शेलके <br />
//                   सहाय्यक नगर रचनाकार
//                   <br />
//                   अहमदनगर महानगरपालिका, अहमदनगर
//                 </p>
//               </div>
//             </Row>
//           </div>
//         </>
//       ) : (
//         "Permission Not Approved Please Wait"
//       )}
//     </>
//   );
// };

// export default FinalPermission;
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { server } from "../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const FinalPermission = () => {
  const [data, setData] = useState([]);
  const [view, setView] = useState("certificate"); // Toggle between 'certificate' and 'details'
  const { id } = useParams();

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = () => {
    server
      .get(`/user_appl/userapplication/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {data.permission_status === "APPROVED" ? (
        <div className=" mt-4">
          <Row className="justify-content-end mb-3">
            <Col xs="auto">
              <Button onClick={handlePrint} variant="primary">
                Print
              </Button>
            </Col>
          </Row>
          <div className="certificate p-4 shadow-sm border rounded bg-white shadow">
            {/* Header */}
            <Row className="justify-content-center align-items-center mb-4 text-center">
              <Col xs={2}>
                <Image src="/logo.png" className="img-fluid" alt="AMC Logo" />
              </Col>
              <Col xs={8}>
                <h1 className="mb-2">अहमदनगर महानगरपालिका, अहमदनगर</h1>
                <p className="mb-1">(नगररचना व अतिक्रमण निर्मूलन विभाग)</p>
                <div>
                  Website:{" "}
                  <a
                    href="https://amcfest.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://amcfest.in/
                  </a>{" "}
                  &nbsp; E-mail:{" "}
                  <a href="mailto:amc_anr@rediffmail.com">
                    amc_anr@rediffmail.com
                  </a>
                </div>
                <div>it.nagar@amc.gov.in</div>
              </Col>
              <Col xs={2}>
                <Image
                  src="/bharat.jpeg"
                  className="img-fluid"
                  alt="Bharat Image"
                />
              </Col>
            </Row>

            {/* Certificate Content */}
            <>
              <hr />
              <div
                className="text-center mb-4"
                style={{
                  border: "1px solid black",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <h2 className="mb-4">गणेशोत्सव - २०२४</h2>
                <h3 className="mb-2">अहमदनगर महानगरपालिका, अहमदनगर</h3>
                <h4 className="mb-2">मंडळ परवाना - {data.id}</h4>
                <h3 className="mb-2">गणेश मंडळाचे नाव - {data.mandal_name}</h3>
                <h3 className="mb-2">मंडपाचे ठिकाण - {data.mandap_address}</h3>
                <h3 className="mb-2">
                  परवाना कालावधी - {data.mandap_duration} &nbsp; दिवस
                </h3>
              </div>
              <Row className="text-center mb-4">
                <Col>
                  <img src="/esign.png" width={100} alt="" />
                  <p>जितेंद्र शेळके</p>
                  <p> सहाय्यक नगररचनाकार</p>
                  <p>अहमदनगर महानगरपालिका, अहमदनगर</p>
                </Col>
              </Row>
              <hr />
            </>
          </div>
          <div
            className="certificate p-4 shadow-sm border rounded bg-white shadow"
            style={{ marginTop: "10rem" }}
          >
            {/* Header */}
            <Row className="justify-content-center align-items-center mb-4 text-center">
              <Col xs={2}>
                <Image src="/logo.png" className="img-fluid" alt="AMC Logo" />
              </Col>
              <Col xs={8}>
                <h1 className="mb-2">अहमदनगर महानगरपालिका, अहमदनगर</h1>
                <p className="mb-1">(नगररचना व अतिक्रमण निर्मूलन विभाग)</p>
                <div>
                  Website:{" "}
                  <a
                    href="https://amcfest.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://amcfest.in/
                  </a>{" "}
                  &nbsp; E-mail:{" "}
                  <a href="mailto:amc_anr@rediffmail.com">
                    amc_anr@rediffmail.com
                  </a>
                </div>
                <div>it.nagar@amc.gov.in</div>
              </Col>
              <Col xs={2}>
                <Image
                  src="/bharat.jpeg"
                  className="img-fluid"
                  alt="Bharat Image"
                />
              </Col>
            </Row>

            {/* Certificate Content */}
            <>
              <hr />
              <Row className="mb-4">
                <Col>
                  <div className="d-flex justify-content-between">
                    <p>Permission No. - {data.id}</p>
                    <p>दिनांक: {new Date().toLocaleDateString()}</p>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <p>
                    प्रति, <br /> {data.name} <br /> {data.mandal_name}
                    &nbsp;{data.mandal_address}
                  </p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <p>
                    विषय: &nbsp;
                    {(() => {
                      switch (data.festival_name) {
                        case "ganesh_utsav":
                          return "गणेश उत्सव";
                        case "navratri":
                          return "नवरात्री";
                        case "shiv_jayanti":
                          return "शिव जयंती";
                        case "ambedkar_jayanti":
                          return "Ambedkar Jayanti";
                        case "other":
                          return "Other";
                        default:
                          return data.festival_name; // Fallback if no condition matches
                      }
                    })()}
                    &nbsp; निमित्त सार्वजनिक जागेवर तात्पुरता मंडप उभारण्यास
                    परवानगी मिळणे बाबत.
                  </p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <p>
                    संदर्भ: आपला दि.{" "}
                    {new Date(data.created_at).toISOString().split("T")[0]}{" "}
                    रोजीचा अर्ज उपरोक्त संदर्भीय अर्जानुसार कळविण्यांत येते की,{" "}
                    {data.festival_name} निमित्त मंडप उभारण्यास आपण परवानगी
                    मागितलेली आहे.
                    <br />
                    <strong>महोदय,</strong>
                    <br />
                    आपल्या विनंतीनुसार {data.mandap_address} या ठिकाणी दि.
                    {data.mandap_startdate} ते {data.mandap_remove_date} या
                    कालावधीत &nbsp;{data.mandap_length} x {data.mandap_width} या
                    मोजमापाचे मंडप खालील अटी व शर्तीं अधिन राहून परवानगी
                    देण्यांत येत आहे:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul>
                    {[
                      " मा.शासनाने सार्वजनिक गणेशोत्सव 2021 मार्गदर्शक सुचना क्रमांक आरएलपी-0621/प्र.क्र. 144/वि.शा.1ब दि.29 जुन 2021 अन्वये दिलेल्या मार्गदर्शक सुचनांचे काटेकोरपणे अंमलबजावणी करण्यांत यावी.",
                      "विविध संसर्गजन्य आजाराचे गांभीर्य लक्षात घेता मंडपात गर्दी होणार नाही याची दक्षता घेण्यांत यावी.",
                      "गणपती मंडपा मध्ये निर्जतुकीकरणाची पर्याप्त व्यवस्था करण्यांत यावी.",
                      "देण्यांत आलेल्या परवानगी पेक्षा जास्त मोजमापाचा मंडप उभारल्यास कायदेशिर कारवाई करण्यांत येईल.",
                      " सार्वजनिक ठिकाणी मंडप उभारतांना स्थानिक रहिवाशांना अडथळा व उपद्रव निर्माण होता कामा नये.",
                      "सार्वजनिक रस्त्यावरील वाहतुकीस व नागरिकांना अडथळा होता कामा नये.",
                      "सार्वजनिक जागेवर मंडप उभारतांना रस्त्याचा किमान 60%  व त्या पेक्षा जास्त भाग वाहतुकीस खुला राहील याची खबरदारी घेण्यांत यावी.",
                      "सार्वजनिक रस्त्यावर मंडप उभारतांना सदर रस्त्यावरुन अँम्बुलंस,अग्निशमन बंब इत्यादी व्यवस्थितरित्या जावु शकेल इतका रस्ता खुला ठेवण्यांत यावा व मंडपाच्या ठिकाणी अग्नीशनम व्यवस्था ठेवणे बंधनकारक आहे",
                      "म.न.पा च्या सार्वजनिक मालमत्तेचे नुकसान झाल्यास ते दंडात्मक कारवाई करुन आपणा कडुन वसुल करण्यांत येईल. सार्वजनिक रस्त्याचे नुकसान केल्यास/मंडप उभारतांना रस्ता खोदल्याचे आढळुन आल्यास संबधीताकडुन दंडात्मक कारवाई करण्यांत येईल.",
                      "कुठल्याही प्रकारे सार्वजनिक शांतता भंग होता कामा नये.",
                      "उत्सवाच्या काळात कुठल्याही प्रकारची जिवित व वित्तहानी झाल्यास त्यास आपणांस जबाबदार धरुन कायदेशिर कारवाई करण्यांत येईल.",
                      "सहा.धर्मदाय आयुक्त यांचे कडील मंडळाची/ट्रस्टची नोंदणी बंधनकारक आहे.",
                      "मा.उच्च न्यायालय मुंबई येथील जनहित याचिका क्र.155/2011 मधील  व मा.शासन यांनी दिलेल्या आदेशाची काटेकोरपणे पालन करण्यांची जबाबदारी आपणांवर बंधनकारक राहील. तसेच सदर ठिकाणी कोणत्याही प्रकारचे फ्लेक्स बोर्ड, होर्डींग्ज लावता येणार नाही.",
                      "देण्यांत आलेल्या परवानगीचा तपशील मंडपाच्या दर्शनी भागावर ठळकपणे लावण्यांत यावा",
                      "पारंपारीक मिरवणुक मार्गावरील आपण उभारलेला मंडप, आरास, देखावे, पतका, विद्युत रोषणाई, झेंडे, रस्त्याचे बाजुचे मंड्पाची झालर इत्यादीमुळे कोणताही अडथळा होता कामा नये.",
                      "सदर मंड्पा मध्ये व परिसरातील मंडपाचे आजुबाजुचे रस्ते छायाचित्रीकरण होईल अशा योग्य रितीने किमान चार सीसीटीव्ही कँमेरे बसवुन ते अहोरात्र सुरु ठेवण्यांत यावे.",
                      "पोलीस प्रशासनाकडील खालील प्रमाणे गणेशोत्सव मंडळ उत्सव सुचना 2023 चे काटेकोरपणे पालन करण्यांत यावे.",
                      "सार्वजनिक गणेश मंडळांनी ऑनलाईन नोंदणी करावी/सार्वजनिक गणेश मंडळांनी महावितरण कंपनीकडुन अधिकृत तात्पुरती वीज जोडणी करावी.",
                      " अक्षेपार्ह घोषवाक्य प्रक्षोभक देखाव्यावरुन कोणाच्याही भावना दुखवणार नाही याची दक्षता घावी.",
                      " मोठया गणेश उत्सव मंडळांनी रहदारीस अडथळा निर्माण होणार नाही याची दक्षता घ्यावी.",
                      "ध्वनीक्षेपक वापरावरील निर्बधाचे अनुषंगाने प्रदुषण नियंत्रण कायद्या अंतर्गत तरतुदींचे तसेच मा.सर्वोच न्यायालयाकडील निर्देशांचे तंतोतंत पालन करावे.",
                      "देखावा पाहण्यासाठी येणा-या महिलांसाठी स्वतंत्र व्यवस्था करण्यांत यावी व कोणत्याही प्रकारे छेडछाड होणार नाही याची दक्षता घेवुन स्वंयसेवक नेमावे.",
                      "गणेश स्थापने मुळे इतर धर्मियांचे धार्मिक स्थळास कोणत्याही प्रकारे अडथळा होणार नाही याची दक्षता घ्यावी.",
                      "मिरवणुकीत वापरण्यांत येणारी वाहने सुस्थितीत असले बाबत अगोदरच आरटीओ कडुन तपासणी करुन घ्यावी.",
                      "संबधीत वाहनावरील चालक हा देखील लायसन्सधारक असावा त्याने मद्यार्काचे सेवन केलेले नसावे.",
                      " स्थापना मिरवणुक/विसर्जन मिरवणुक काढणार असल्याचे त्यांचे व्हीडीओ शुटींग करावे तसेच आवश्यकता भासल्यास पोलीसांना द्यावी.",
                      " कोणत्याही प्रकारच्या बँग पिशव्या मंडपात दर्शनाच्या ठिकाणी आणण्यांस प्रतिबंध करावा.",
                      "काही संशयीतची वस्तु मंडळाचे आसपास आढळल्यास त्या बाबत तात्काळ पोलीसांना संपर्क करावा व शक्यतो अशा वस्तु हाताळु नये.",
                      " श्री गणेश प्रतिष्ठापनेचा मंडप भक्कम व उंच असावा.",
                      " श्री गणेशोत्सव मंडळापासुन किमान 50 मिटरचे आत ठेवु नये, 50 मिटरचे वर अंतरावर पार्कींगचे व्यवस्था करणेत यावी.",
                      "श्री गणेश मुर्तीचे संरक्षणाकरीता 24 तास स्वंयसेवकाची नेमणुक करण्यांत यावी.",
                      "मंडळाने सर्व एरिया कव्हर होईल अशा प्रकारे सीसी टीव्ही बसवावेत.",
                      "विनापरवाना वर्गणी गोळा करु नये, तसेच वर्गणी गोळा करतांना कोणत्याही प्रकारे बळजबरी करु नये असे निदर्शनास आल्यास कायदेशिर कारवाई करण्यांत येईल.",
                      "श्री गणेश तरुण मंडळाचे वतीने टाकण्यांत आलेले मंड्प पुर्व परवानगी घेवुन व नियमानुसार टाकावे. अनाधिकृत मंडप टाकल्यास योग्य ती कायदेशिर कारवाई करण्यांत येईल.",
                      "गणेश उत्सव मंडळाने अनाधिकृत फ्लेक्स,जाहिराती फलक लावु नये तसेच त्यावर आक्षेपार्ह मजकुर छापु नये.",
                      "पोलीस स्टेशनला गणेश विसर्जनाच्या ठिकाणाची माहिती द्यावी.विसर्जन करतांना धोकादायक विहीर अथवा जलस्त्रोत या ठिकाणी गणेश विसर्जन करू नये. अशा ठिकाणी अपघात होण्याची शक्यता असते. त्या बाबत काळजी घ्यावी.",
                      "वरील पैकी कुठल्याही अटी व शर्तीचा भंग झाल्यास अथवा तक्रार प्राप्त झाल्यास दिलेला परवाना त्वरीत रद्य करण्यांत येईल.",
                    ].map((condition, index) => (
                      <li key={index}>{condition}</li>
                    ))}
                  </ul>
                </Col>
              </Row>

              <Row className="text-end mb-4">
                <Col>
                  <img src="/esign.png" width={100} alt="" />
                  <p>जितेंद्र शेळके</p>
                  <p> सहाय्यक नगररचनाकार</p>
                  <p>अहमदनगर महानगरपालिका, अहमदनगर</p>
                </Col>
              </Row>
            </>
          </div>
        </div>
      ) : (
        <Container>
          <p className="text-center">Permission status is not approved.</p>
        </Container>
      )}
    </>
  );
};

export default FinalPermission;
