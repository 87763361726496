import React from "react";
import { NavLink } from "react-router-dom";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";

const Navbar = () => {
  return (
    <div>
      <div>
        <div className="border-bottom">
          <div className="row text-center">
            <div className="col-md-2 text-center">
              <a className="navbar-brand" href="#">
                <img src="/logo.png" width={150} alt="Logo" />
              </a>
            </div>

            <div className="col-md-7 align-self-center text-center">
              <div className="">
                <h1 className="fw-bold">अहमदनगर महानगरपालिका,अहमदनगर </h1>
              </div>
            </div>

            <div className="col-md-2 align-self-center">
              {/* <a className="navbar-brand" href="#">
                <img src="/bharat.jpeg" width={180} alt="Logo" />
              </a> */}

              <div className="text-end ">
                <Dropdown>
                  <MenuButton
                    className="bg-primary text-white ms-3"
                    style={{ width: "6rem" }}
                  >
                    LOGIN
                  </MenuButton>
                  <Menu className="mt-4">
                    <MenuItem>
                      <NavLink
                        to="/super-admin"
                        className="dropdown-item text-uppercase "
                      >
                        DMC Officer
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/divisional-user"
                        className="dropdown-item text-uppercase "
                      >
                        Divisional Officer
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        to="/nagar-department"
                        className="dropdown-item text-uppercase "
                      >
                        Nagar Rachana Officer
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        className="dropdown-item text-uppercase"
                        to="/pwd-department"
                      >
                        MSEDCL Department
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        className="dropdown-item text-uppercase"
                        to="/police-department"
                      >
                        Police Department
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        className="dropdown-item text-uppercase"
                        to="/traffic-police"
                      >
                        Traffic Police Department
                      </NavLink>
                    </MenuItem>
                    <MenuItem>
                      <NavLink
                        className="dropdown-item text-uppercase"
                        to="/fire-department"
                      >
                        Fire Department
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-auto my-2">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "btn btn-danger text-white px-4 py-2"
                  : "btn btn-light px-4 py-2"
              }
            >
              Festival Mandap Application Form
            </NavLink>
          </div>

          <div className="col-auto my-2">
            <NavLink
              to="/trackapplication"
              className={({ isActive }) =>
                isActive
                  ? "btn btn-danger text-white px-4 py-2"
                  : "btn btn-light px-4 py-2"
              }
            >
              Festival Mandap Application Status
            </NavLink>
          </div>

          <div className="col-auto my-2">
            <NavLink
              to="/download"
              className={({ isActive }) =>
                isActive
                  ? "btn btn-danger text-white px-4 py-2"
                  : "btn btn-light px-4 py-2"
              }
            >
              Downloads
            </NavLink>
          </div>

          <div className="col-auto my-2">
            <NavLink
              to="/murticarform"
              className={({ isActive }) =>
                isActive
                  ? "btn btn-danger text-white px-4 py-2"
                  : "btn btn-light px-4 py-2"
              }
            >
              Murtikar/Stockist/Sale
            </NavLink>
          </div>

          <div className="col-auto my-2">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "btn btn-danger text-white px-4 py-2"
                  : "btn btn-light px-4 py-2"
              }
            >
              Contact
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
