import React, { useState } from "react";
import { server } from "../../common";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { NavLink } from "react-router-dom";

const TrackApplication = () => {
  const [dashboard, setDashboard] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState("");

  // search filter wise
  const FilterData = () => {
    server
      .get(`/user_appl/userapplication/${applicationNumber}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setDashboard([response.data]); // Store the response data in an array
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          Festival Permission Application Status
        </h3>
      </div>
      <div className="container mt-4">
        <div className="card mb-3">
          <div className="card-header">Application Status</div>
          <div className="card-body row">
            <div className="form-group col-md-5">
              <label>अँप्लिकेशन नंबर :</label>
              <input
                type="text"
                className="form-control"
                placeholder="अँप्लिकेशन नंबर:"
                value={applicationNumber}
                onChange={(e) => setApplicationNumber(e.target.value)}
              />
            </div>
            <div className="col-md-2 align-self-center">
              <button
                className="btn btn-info btn-primary my-2"
                onClick={FilterData}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {dashboard.length > 0 && (
          <div className="card mt-4">
            <div className="card-header">Applications List</div>
            <div className="card-body">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Application ID</th>
                    <th>Festival Name</th>
                    <th>Status</th>
                    <th>Applicant Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboard.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.festival_name}</td>
                      <td>{item.permission_status}</td>
                      <td>{item.name}</td>
                      <td>
                        {item.permission_status === "APPROVED" ? (
                          <NavLink
                            className="btn btn-info"
                            to={`/finalpermission/${item.id}`}
                            target="_blank"
                          >
                            Download
                          </NavLink>
                        ) : item.permission_status === "REJECTED" ? (
                          <div>{item.rejection_reason}</div>
                        ) : (
                          "In Process"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TrackApplication;
