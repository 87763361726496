import React from "react";
import TotalMurticarApplications from "../../Administration/TotalMurticarApplications";

const SuperAdminMurtiTotalAppl = () => {
  return <div>
    <TotalMurticarApplications role="Super Admin"/>
  </div>;
};

export default SuperAdminMurtiTotalAppl;
