import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { server } from "../../common";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";
import { AxiosError } from "axios";

function UserApplicationModal({ show, handleClose, id, role }) {
  const [data, setData] = useState(null);
  const user = useSelectAccess("Police Department");
  const fileInputRef = useRef();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [showfileupload, setShowfileupload] = useState(false);

  const handleClosefile = () => setShowfileupload(false);
  const handleShowfile = () => setShowfileupload(true);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  };

  useEffect(() => {
    if (show && id) {
      getData(id);
    }
  }, [show, id]);

  const getData = (applicationId) => {
    server
      .get(`/user_appl/userapplication/${applicationId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  const AddProfilePicture = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("police_NOC", fileInputRef.current.files[0]);
    try {
      server.post(`/user_appl/updateapplication/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: user.authToken,
        },
      });
      toast.success("File Uploaded successfully");
      handleClosefile();
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
        console.log(error.message);
      }
    }
  };

  // Update Status function
  const updateStatus = async (status) => {
    try {
      await server.post(
        `/user_appl/updateapplication/${id}`,
        { permission_status: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        }
      );
      toast.success(`Permission Status ${status} successfully`);
      getData(id); // Reload data after status update
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {data
              ? `Application Details for ${data.festival_name} (Application No. ${data.id})`
              : "Application Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="4">Application Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bold">Applicant Id</td>
                    <td>{data.id}</td>
                    <td className="fw-bold">Contact</td>
                    <td>{data.mobile}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Applicant Name</td>
                    <td>{data.name}</td>
                    <td className="fw-bold">Email ID</td>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Address</td>
                    <td>{data.address}</td>
                    <td className="fw-bold">Mandal Name</td>
                    <td>{data.mandal_name}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Festival Name</td>
                    <td>{data.festival_name}</td>
                    <td className="fw-bold">Department Divisional Office</td>
                    <td>{data.department_divisional_office}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Fire Department</td>
                    <td>{data.fire_department}</td>
                    <td className="fw-bold">Fire Prevention Measures</td>
                    <td>{data.fire_prevention_measures}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Kaman Address</td>
                    <td>{data.kaman_address}</td>
                    <td className="fw-bold">Kaman Height</td>
                    <td>{data.kaman_height}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Kaman Number</td>
                    <td>{data.kaman_number}</td>
                    <td className="fw-bold">Loudspeaker Approval</td>
                    <td>{data.loudspeaker_approval}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Mandal Address</td>
                    <td>{data.mandal_address}</td>
                    <td className="fw-bold">Mandal Registration Number</td>
                    <td>{data.mandal_registration_number}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Mandap Address</td>
                    <td>{data.mandap_address}</td>
                    <td className="fw-bold">Mandap Capacity Footpath Width</td>
                    <td>{data.mandap_capacity_footpath_width}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Mandap Duration</td>
                    <td>{data.mandap_duration}</td>
                    <td className="fw-bold">Mandap Lenght</td>
                    <td>{data.mandap_length}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Mandap Start Date</td>
                    <td>{data.mandap_startdate}</td>
                    <td className="fw-bold">Mandap Remove Date</td>
                    <td>{data.mandap_remove_date}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Mandap Width</td>
                    <td>{data.mandap_width}</td>
                    <td className="fw-bold">Mandap Area</td>
                    <td>{data.mandap_area}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Permission Obtained Previously</td>
                    <td>{data.permission_obtained_previously}</td>
                    <td className="fw-bold">Police Department</td>
                    <td>{data.police_department}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">road_footpath_clear_citizen</td>
                    <td>{data.road_footpath_clear_citizens}</td>
                  </tr>
                </tbody>
              </Table>

              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="5" className="text-dark">
                      Document Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>आधार कार्ड</td>
                    <td>
                      <a
                        href={data.adharcard}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>मंडळातील पदाधिकारी / सदस्यांची यादी</td>
                    <td>
                      <a
                        href={data.member_list_mobile}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>ट्रॅफिक - हमीपत्र </td>
                    <td>
                      <a
                        href={data.traffic_declaration}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr> */}
                  <tr>
                    <td>मंडपाचा स्थल दर्शक नकाशा</td>
                    <td>
                      <a
                        href={data.site_location_map}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="5" className="text-dark">
                      Application Status
                    </th>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <th>Is Approved?</th>
                    <th>Approved By</th>
                    <th>Approved Date/Time</th>
                    <th>Status</th>
                    <th>Reject Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nagarrachana Officer</td>
                    <td className="text-center">
                      {data.permission_status === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.finalpermissionuploadby}</td>
                    <td>{data.uploaddatefinalpermission}</td>
                    {/* <td>
                      {data.nmc_permission ? (
                        <a
                          href={data.nmc_permission}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td> */}
                    <td>{data.statusbynagarrachana}</td>
                  </tr>
                  <tr>
                    <td>Divisional Officer</td>
                    <td className="text-center">
                      {data.statusbydivision === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.uploadedbydivisionofficer}</td>
                    <td>{data.uploaddatedivisionofficer}</td>
                    {/* <td>
                      {data.nmc_permission ? (
                        <a
                          href={data.nmc_permission}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td> */}
                    <td>{data.statusbydivision}</td>
                  </tr>
                  <tr>
                    <td>Police Department</td>
                    <td className="text-center">
                      {data.statusbypolice === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.uploadedbypolice}</td>
                    <td>{data.uploaddatepolice}</td>
                    <td>{data.statusbypolice}</td>
                  </tr>
                  <tr>
                    <td>Fire Department</td>
                    <td className="text-center">
                      {data.statusbyfire === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.uploadedbyfire}</td>
                    <td>{data.uploadeddatebyfire}</td>
                    {/* <td>
                      {data.fire_noc ? (
                        <a
                          href={data.fire_noc}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td> */}
                    <td>{data?.statusbytraffic}</td>
                  </tr>
                  <tr>
                    <td>Traffic Department</td>
                    <td className="text-center">
                      {data.statusbytraffic === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.uploadedbytraffic}</td>
                    <td>{data.uploaddatetraffic}</td>
                    {/* <td>
                      {data.traffic_polic_noc ? (
                        <a
                          href={data.traffic_polic_noc}
                          target="_blank"
                          className="btn btn-success btn-sm"
                        >
                          View
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td> */}
                    <td>{data?.statusbytraffic}</td>
                  </tr>

                  <tr>
                    <td>MSEDCL Department</td>
                    <td className="text-center">
                      {data.statusbypwd === "APPROVED" ? (
                        <span className="text-success">&#10004;</span>
                      ) : (
                        <span className="text-danger">&#10060;</span>
                      )}
                    </td>
                    <td>{data.uploadedbypwd}</td>
                    <td>{data.uploaddatepwd}</td>
                    {/* <td>
                      {data.pandal_location_map ? (
                        <>
                          <a
                            href={data.pandal_location_map}
                            target="_blank"
                            className="btn btn-success btn-sm "
                          >
                            View
                          </a>
                          <a
                            href={data.gps_photo}
                            target="_blank"
                            className="btn btn-success btn-sm ms-2"
                          >
                            View
                          </a>
                          <a
                            href={data.site_verification_report}
                            target="_blank"
                            className="btn btn-success btn-sm ms-2"
                          >
                            View
                          </a>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td> */}
                    <td>{data.statusbypwd}</td>
                  </tr>
                </tbody>
              </Table>
              <div className="form-check my-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="termsCheck">
                  वरीप्रमाणे, आयोजित कार्यक्रमासाठी मंडप/स्टेज उभारण्यास परवानगी
                  देत आहोत. मंडप/स्टेज उभारताना मा. उच्च न्यायालय, मुंबई व
                  अहमदनगर महानगरपालिका यांचे सर्व नियम व मार्गदर्शक तत्वांचे
                  पालन करणे आवश्यक आहे. मंडप/स्टेज मुळे वाहतुकीस अथवा रहदारीस
                  कोणताही अडथळा निर्माण होणार नाही याची जबाबदारी आयोजकांची
                  राहील. कार्यक्रम संपल्यानंतर मंडप/स्टेज त्वरित काढण्यात येईल.
                  सदर अटी व शर्तींचे उल्लंघन झाल्यास आयोजकांना कायदेशीर कारवाईस
                  सामोरे जावे लागेल.
                </label>
              </div>

              <button
                className="btn btn-info"
                onClick={() => updateStatus("APPROVED")}
                disabled={!isChecked || data?.statusbypolice === "APPROVED"}
              >
                {data?.statusbypolice === "APPROVED" ? "Approved" : "Approve"}
              </button>
              <button
                className="btn btn-danger ms-2"
                onClick={() => updateStatus("REJECTED")}
                disabled={!isChecked || data?.statusbypolice === "APPROVED"}
              >
                Reject
              </button>

              {/* {!data.police_NOC && (
                <Button variant="primary" onClick={handleShowfile}>
                  Upload Police NOC
                </Button>
              )} */}
            </>
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for file upload */}
      <Modal show={showfileupload} onHide={handleClosefile}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Police NOC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            accept="application/pdf, image/jpeg, image/png"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosefile}>
            Cancel
          </Button>
          <Button variant="primary" onClick={AddProfilePicture}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserApplicationModal;
