import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PoliceDeparmentSidebar from "../policeDeparment/PoliceDepartmentSidebar";
// import { Outlet } from "react-router-dom";

const PoliceDepartmentContainer = () => {
  document.title = "Police Department";
  const police = useSelector((store) => store.police);

  return (
    <>
      <div className="row ">
        <PoliceDeparmentSidebar role="Police Department" />
        <div className="container">
          {police ? <Outlet /> : <Navigate to="/police-department" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default PoliceDepartmentContainer;
