export const loginSuperAdmin = (session) => {
  return {
    type: "LOGIN_SUPER_ADMIN",
    payload: session,
  };
};

export const logoutSuperAdmin = () => {
  return {
    type: "LOGOUT_SUPER_ADMIN",
  };
};


// export const setSuperAdminDashboard = (dashboard) => {
//   return {
//     type: "UPDATE_SUPER_ADMIN_DASHBOARD",
//     payload: dashboard,
//   };
// };

// export const clearSuperAdminDashboard = () => {
//   return {
//     type: "CLEAR_SUPER_ADMIN_DASHBOARD",
//   };
// };

