import superAdminReducer from "./superAdminReducers.js";
import trafficUserReducers from "./trafficUserReducers.js";
import diviOfficeUserReducer from "./diviOfficeUserReducer.js";
import PoliceUserReducers from "./PoliceUserReducers.js";
import fireDepartmentReducer from "./fireDepartmentReducer.js";
import PwdUserReducer from "./PwdUserReducer.js";
import nagarUserReducers from "./nagarUserReducer.js";

const storeReducer = {
  police: PoliceUserReducers,
  superadmin: superAdminReducer,
  divisionofficer: diviOfficeUserReducer,
  traffic: trafficUserReducers,
  fireuser: fireDepartmentReducer,
  pwduser: PwdUserReducer,
  nagaruser: nagarUserReducers
};

export default storeReducer;
