import axios from "axios";
import { BACKEND_SERVER_URL, trafficUserTokenName, superAdminTokenName, policeTokenName, fireTokenName, pwdTokenName, ngrrachanaTokenName, } from "./config";

export const server = axios.create({
  baseURL: BACKEND_SERVER_URL,
  proxy: BACKEND_SERVER_URL,
});


export const removeSuperAdminToken = () => {
  return sessionStorage.removeItem(superAdminTokenName);
};

export const removeNagarUserToken = () => {
  return sessionStorage.removeItem(ngrrachanaTokenName);
};

export const removeTrafficUserToken = () => {
  return sessionStorage.removeItem(trafficUserTokenName);
};

export const removeDiviOfficeUserToken = () => {
  return sessionStorage.removeItem(trafficUserTokenName);
};

export const removePoliceUserToken = () => {
  return sessionStorage.removeItem(policeTokenName);
};

export const removeFireUserToken = () => {
  return sessionStorage.removeItem(fireTokenName);
};

export const removePWDUserToken = () => {
  return sessionStorage.removeItem(pwdTokenName);
};

// export const getIndustryUserToken = () => {
//   return sessionStorage.setItem(industryUserTokenName);
// };


export const blobToFile = (data) => {
  return data ? window.URL.createObjectURL(data) : null;
};

export const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + " ...";
  }
  return text;
};
