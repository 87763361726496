export const loginPolice = (session) => {
  return {
    type: "LOGIN_POLICE",
    payload: session,
  };
};
export const logoutPolice = () => {
  return {
    type: "LOGOUT_POLICE",
  };
};
