import { removeTrafficUserToken } from "../../common";
import { trafficUserTokenName } from "../../config";

const getTrafficFromLocalStorage = () => {
    try {
        const traffic = JSON.parse(sessionStorage.getItem(trafficUserTokenName));
        if (traffic && traffic.authToken && traffic.loggedInTime) {
            return traffic
        } else {
            removeTrafficUserToken();
            return null
        }
    } catch (error) {
        removeTrafficUserToken()
        return null
    }
};

const getInitial = () => {
    return getTrafficFromLocalStorage();
};

const trafficUserReducers = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_TRAFFIC":
            const { loggedInTime, authToken } = action.payload;
            const newState = {
                loggedInTime: loggedInTime || Date.now(),
                authToken: authToken,
            };
            sessionStorage.setItem(trafficUserTokenName, JSON.stringify(newState));
            return newState;
        case "LOGOUT_TRAFFIC":
            removeTrafficUserToken();
            return null;
        default:
            return state;
    }
};

export default trafficUserReducers;