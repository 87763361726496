import React, { useEffect, useState } from "react";
import { server } from "../../common";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useSelectAccess } from "../../store/stateFunctions";
import { FaListAlt } from "react-icons/fa";
import { CiClock1 } from "react-icons/ci";
import { MdOutlineDone } from "react-icons/md";
import { CgDanger } from "react-icons/cg";

const NagarrachanaDashboard = ({ role }) => {
  const [ganeshUtsav, setGaneshUtsav] = useState({});
  const [navratri, setNavratri] = useState({});
  const [shivJayanti, setShivJayanti] = useState({});
  const [other, setOther] = useState({});

  const user = useSelectAccess(role);

  useEffect(() => {
    getDashboard();
  }, []);
  const getDashboard = () => {
    server
      .get("/division/statuswisenagarrachana", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        const data = response.data[0];
        setGaneshUtsav(data.ganesh_utsav);
        setNavratri(data.navratri);
        setShivJayanti(data.shiv_jayanti);
        setOther(data.other);
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Failed to connect to server");
        }
      });
  };

  const renderCard = (title, data) => (
    <div className="card mb-3">
      <div className="card-header bg-primary text-white">{title}</div>
      <div className="card-body">
        <div className="row text-center">
          <div className="col">
            <FaListAlt className="fs-2" />
            <h4 className="fs-2">{data?.totalAppl}</h4>
            <p>Total Applications</p>
          </div>

          <div className="col">
            <CiClock1 className="fs-2" />
            <h4 className="fs-2">{data?.pendingAppl}</h4>
            <p>Pending Applications</p>
          </div>

          <div className="col">
            <MdOutlineDone className="fs-2" />
            <h4 className="fs-2">{data?.approvedAppl}</h4>
            <p>Approved Applications</p>
          </div>

          <div className="col">
            <CgDanger className="fs-2" />
            <h4 className="fs-2">{data?.rejectedAppl}</h4>
            <p>Rejected Applications</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container my-5">
      <div className="container mt-4">
        {renderCard("Ganesh Utsav", ganeshUtsav)}
        {renderCard("Navratri", navratri)}
        {renderCard("Shiv Jayanti", shivJayanti)}
        {renderCard("Other", other)}
      </div>
    </div>
  );
};

export default NagarrachanaDashboard;
