import React from "react";

const DivisionWiseReport = () => {
  return (
    <>
      <div className="container mt-4">
        <h5>Division Wise Application Report as on Date 20/08/2024</h5>
        <div className="card mb-3">
          <div className="card-header">
            Search Division Wise Application Report
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application Number"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application Mandal Name"
                />
              </div>

              <div className="btn btn-primary col-md-2 btn-sm">Submit</div>
            </div>
          </div>
        </div>

        <div className=" mt-4">
          <div className="card mb-3">
            {/* <div className="card-header">Approved Application</div> */}
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr.No.</th>
                      <th>Division</th>
                      <th>Total Application </th>
                      <th>Total Rejected </th>
                      <th>Total Final Approved </th>
                      <th>Total Pending </th>
                      <th>Total Final Noc</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>नाशिक पूर्व </td>
                      <td> 1161</td>
                      <td> 1161</td>
                      <td> 1161</td>
                      <td> 1161</td>
                      <td> 1161</td>
                    </tr>
                    {/* Add more rows here */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DivisionWiseReport;
