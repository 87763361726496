// import {
//   AiOutlineDashboard,
//   AiOutlineUser,
//   AiOutlinePaperClip,
//   AiFillHdd,
// } from "react-icons/ai";
// import { PiStudent } from "react-icons/pi";
// import { Layout, Menu } from "antd";
// import { Outlet, useNavigate } from "react-router-dom";

// const { Sider, Content } = Layout;

// const SuperAdminSidebar = ({ role }) => {
//   const navigate = useNavigate();

//   return (
//     <Layout>
//       <Sider
//         width={300}
//         style={{
//           height: "100vh",
//           position: "fixed",
//           left: 0,
//           top: 0,
//           bottom: 0,
//           //   zIndex: 1000,
//           backgroundColor: "#fff",
//         }}
//       >
//         <div className="bg-light p-2">
//           <h1 className="text-dark text-center">Admin Panel</h1>
//         </div>
//         <Menu
//           theme="light"
//           mode="inline"
//           defaultSelectedKeys={["dashboard"]}
//           onClick={({ key }) => {
//             if (key === "signout") {
//               // Handle sign out
//             } else {
//               navigate(key);
//             }
//           }}
//           items={[
//             {
//               key: "dashboard",
//               icon: <AiOutlineDashboard className="fs-4" />,
//               label: "Dashboard",
//             },

//             {
//               key: "postinternships",
//               icon: <PiStudent className="fs-4" />,
//               label: "Applications",
//               children: [
//                 {
//                   key: "totalapplication",
//                   icon: <AiFillHdd className="fs-4" />,
//                   label: "Total Applications",
//                 },
//                 {
//                   key: "approvedapplications",
//                   icon: <AiFillHdd className="fs-4" />,
//                   label: "Approved Applications",
//                 },
//                 {
//                   key: "rejectedapplication",
//                   icon: <AiFillHdd className="fs-4" />,
//                   label: "Rejected Applications",
//                 },
//                 {
//                   key: "pendingapplication",
//                   icon: <AiFillHdd className="fs-4" />,
//                   label: "Pending Applications",
//                 },
//                 {
//                   key: "students",
//                   icon: <AiFillHdd className="fs-4" />,
//                   label: "Closed Applications",
//                 },
//               ],
//             },
//           ]}
//         />

//         <div className="btn bg-danger btn-sm w-100 mt-3 text-white">logout</div>
//       </Sider>
//     </Layout>
//   );
// };

// export default SuperAdminSidebar;

import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlinePaperClip,
  AiFillHdd,
} from "react-icons/ai";
import { FaBusinessTime, FaBlogger, FaPhotoVideo } from "react-icons/fa";
import { RiKakaoTalkLine } from "react-icons/ri";
import { MdFeedback } from "react-icons/md";
import { BiMenu, BiPodcast, BiSolidBusiness } from "react-icons/bi";
import { MdComputer, MdOutlinePoll } from "react-icons/md";
// import { Outlet } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
import { PiStudent } from "react-icons/pi";
import { Layout, Menu } from "antd";
import { logoutAccess } from "../../store/stateFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FcBusinessman } from "react-icons/fc";
import { GoOrganization } from "react-icons/go";

import { CiBoxList } from "react-icons/ci";

const { Sider, Content } = Layout;

const FireDeparmentSidebar = ({ role }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div>
      <div className="row bg-primary p-3">
        <div className="col-md-2">
          <a
            className="bg-white p-2 rounded-3 border border-bottom border-2 border-dark shadow"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
            id="side"
          >
            <BiMenu style={{ color: "black", fontSize: 25 }} />
          </a>
        </div>
        <div className="col-md-8 text-center text-white">
          <h4>Welcome To The Ahamadnagar Municipal Corporation, Ahamadnagar</h4>
        </div>
        <div className="col-md-2 text-end">
          <Button
            variant="contained"
            color="error"
            className=" fw-bold"
            onClick={() => logoutAccess("Fire Department")}
          >
            Logout
          </Button>
        </div>
      </div>
      <ul className="nav">
        <li className="nav-item" id="sidebarm">
          <div
            className="offcanvas offcanvas-start"
            style={{ width: "20rem" }}
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header bg-primary text-white ">
              <p id="size" className="fs-4">
                Fire Department
              </p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ">
              <Sider width={300} style={{ height: "100vh" }}>
                <div style={{ height: "100%" }}>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["dashboard"]}
                    onClick={({ key }) => {
                      if (key === "signout") {
                      } else {
                        navigate(key);
                      }
                    }}
                    items={[
                      {
                        key: "dashboard",
                        icon: <AiOutlineDashboard className="fs-4" />,
                        label: "Dashboard",
                      },

                      {
                        key: "Applications",
                        icon: <FaBusinessTime className="fs-4" />,
                        label: "Applications",
                        children: [
                          {
                            key: "totalapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Total Applications",
                          },
                          {
                            key: "approvedapplications",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Approved Applications",
                          },
                          {
                            key: "rejectedapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Rejected Applications",
                          },
                          {
                            key: "pendingapplication",
                            icon: <AiFillHdd className="fs-4" />,
                            label: "Pending Applications",
                          },
                        ],
                      },

                      // {
                      //   key: " Murticar Applications",
                      //   icon: <FaBusinessTime className="fs-4" />,
                      //   label: "Murticar Applications",
                      //   children: [
                      //     {
                      //       key: "totalmurticapplications",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Total Applications",
                      //     },
                      //     {
                      //       key: "approvedapplications",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Approved Applications",
                      //     },
                      //     {
                      //       key: "rejectedapplication",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Rejected Applications",
                      //     },
                      //     {
                      //       key: "pendingapplication",
                      //       icon: <AiFillHdd className="fs-4" />,
                      //       label: "Pending Applications",
                      //     },
                      //   ],
                      // },
                    ]}
                  />
                </div>
              </Sider>
            </div>
          </div>
        </li>
      </ul>

      <Content
        style={{
          // margin: "24px 16px",
          padding: 22,
          // minHeight: 280,
        }}
      >
        {/* <Outlet /> */}
      </Content>
    </div>
  );
};

export default FireDeparmentSidebar;
