

import { removeDiviOfficeUserToken } from "../../common";
import { DiviOfficeUserTokenName } from "../../config";

const getInternalUserFromLocalStorage = () => {
    try {
        const divisionofficer = JSON.parse(sessionStorage.getItem(DiviOfficeUserTokenName));
        if (divisionofficer && divisionofficer.authToken && divisionofficer.loggedInTime) {
            console.log("Returning Internal User:", divisionofficer);
            return divisionofficer;
        } else {
            removeDiviOfficeUserToken();
            return null
        }
    } catch (error) {
        removeDiviOfficeUserToken()
        return null
    }
};


const getInitial = () => {
    return getInternalUserFromLocalStorage();
};

const diviOfficeUserReducer = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_DIVISIONAL_USER":
            state = {};
            const time = action.payload.loggedInTime;
            if (time) {
                state.loggedInTime = time;
            } else {
                state.loggedInTime = Date.now();
            }
            state.authToken = action.payload.authToken;
            sessionStorage.setItem(DiviOfficeUserTokenName, JSON.stringify(state));
            return state;
        case "LOGOUT_DIVISIONAL_USER":
            removeDiviOfficeUserToken();
            return null;
        default:
            return state;
    }
};

export default diviOfficeUserReducer;