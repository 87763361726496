

import { removePWDUserToken } from "../../common";
import { pwdTokenName } from "../../config";

const getPwdUserFromLocalStorage = () => {
    try {
        const pwduser = JSON.parse(sessionStorage.getItem(pwdTokenName));
        if (pwduser && pwduser.authToken && pwduser.loggedInTime) {
            console.log("Returning Internal User:", pwduser);
            return pwduser;
        } else {
            removePWDUserToken();
            return null
        }
    } catch (error) {
        removePWDUserToken()
        return null
    }
};


const getInitial = () => {
    return getPwdUserFromLocalStorage();
};

const PwdUserReducer = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_PWD":
            state = {};
            const time = action.payload.loggedInTime;
            if (time) {
                state.loggedInTime = time;
            } else {
                state.loggedInTime = Date.now();
            }
            state.authToken = action.payload.authToken;
            sessionStorage.setItem(pwdTokenName, JSON.stringify(state));
            return state;
        case "LOGOUT_PWD":
            removePWDUserToken();
            return null;
        default:
            return state;
    }
};

export default PwdUserReducer;