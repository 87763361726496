import React, { useState, useEffect } from "react";
import { server } from "../../common";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import UserApplicationModal from "./UserApplicationModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const swalalert = withReactContent(Swal);
const SuperAdminTotalApplications = ({ role }) => {
  const [data, setData] = useState([]);
  const user = useSelectAccess("Super Admin");
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [applicationNumber, setApplicationNumber] = useState("");

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  // useEffect(() => {
  //   getData();
  // }, []);

  // // Get all data
  // const getData = () => {
  //   server
  //     .get("/user_appl/getallappl", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: user.authToken,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("api response", response.data);
  //       if (response.status === 200 || response.status === 201) {
  //         setData(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error instanceof AxiosError && error.response?.data?.message) {
  //         toast.error(error.response.data.message);
  //       } else {
  //         toast.error(
  //           error.response?.data?.error || "Failed to connect to server"
  //         );
  //       }
  //     });
  // };

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getData(currentPage); // Initial data fetch
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Get all data with pagination
  const getData = (page) => {
    server
      .get(`/user_appl/pagination?page=${page}&limit=10`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data.userApplications); // Set the data array correctly
          setTotalPages(response.data.paginationInfo.totalPages); // Set the total number of pages correctly
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            error.response?.data?.error || "Failed to connect to server"
          );
        }
      });
  };

  // Delete gallery
  const deleteInternShip = (data) => {
    swalalert
      .fire({
        title: "Delete Confirmation!",
        text: `Are You Sure That You Want To Delete This Application ${data.id} Permanently ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: true,
      })
      .then(function (swalObject) {
        if (swalObject.isConfirmed) {
          server
            .delete(`/user_appl/deleteapplication/${data.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: user.authToken,
              },
            })
            .then(function (response) {
              console.log("api response", response.data);
              if (response.status === 200 || response.status === 201) {
                swalalert.fire(
                  "Deleted!",
                  "Contact entry has been deleted.",
                  "success"
                );
                getData();
              }
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } else {
          swalalert.fire(
            "Cancelled",
            "Your imaginary file is safe :)",
            "error"
          );
        }
        //success method
      });
  };

  // search
  const SearchData = (searchValue) => {
    server
      .get(`/user_appl/searchmandalbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="container mt-4">
        <div className="card mb-3">
          <div className="card-header">Search Application</div>
          <div className="card-body row">
            <div className="form-group col-md-5">
              <label>अँप्लिकेशन नंबर :</label>
              <input
                type="search"
                className="form-control"
                placeholder="Search "
                onChange={(e) => SearchData(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="text-end">
          <ReactHTMLTableToExcel
            className="btn btn-primary"
            id="test-table-xls-button"
            table="totalapplications"
            filename={`Total Applications Report ${new Date()}`}
            sheet="Garden Report"
            buttonText="Export To Excel"
          />
        </div>

        <div className="mt-4">
          <div className="card mb-3">
            <div className="card-header">Total Application</div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-striped table-bordered text-center"
                  id="totalapplications"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr.No.</th>
                      <th>Application No.</th>
                      <th>Application Date/Time</th>
                      <th>Festival Name</th>
                      <th>Prabhag</th>
                      <th>Applicant Name</th>
                      <th>Address</th>
                      <th>Contact No.</th>
                      <th>Mandal Name</th>
                      <th>Mandal Address</th>
                      <th>Permission Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data ? (
                      data.map((item, index) => (
                        <tr key={item.id}>
                          <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                          <td>{item.id}</td>
                          <td>
                            {
                              new Date(item.created_at)
                                .toISOString()
                                .split("T")[0]
                            }
                          </td>
                          <td>{item.festival_name}</td>
                          <td>{item.department_divisional_office}</td>
                          <td>{item.name}</td>
                          <td>{item.address}</td>
                          <td>{item.mobile}</td>
                          <td>{item.mandal_name}</td>
                          <td>{item.mandal_address}</td>
                          <td
                            className={
                              item.permission_status === "APPROVED"
                                ? "badge bg-success mt-4 text-white"
                                : "badge bg-danger mt-4 text-white"
                            }
                          >
                            {item.permission_status}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => handleShow(item.id)}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <div
                              className="btn btn-danger btn-sm mt-4"
                              onClick={() => deleteInternShip(item)}
                            >
                              Delete
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Permission Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              </li>

              {Array.from(
                { length: Math.min(10, totalPages) }, // Show up to 10 pages at a time
                (_, index) => {
                  const startPage = Math.max(1, currentPage - 5); // Dynamically calculate start page
                  const page = startPage + index;

                  if (page <= totalPages) {
                    return (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                }
              )}

              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <UserApplicationModal
        show={showModal}
        handleClose={handleClose}
        id={selectedId}
        role={role}
      />
    </>
  );
};

export default SuperAdminTotalApplications;
