import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import FireDeparmentSidebar from "../firedepartment/FireDepartmentSidebar";
// import { Outlet } from "react-router-dom";

const FireDepartmentContainer = () => {
  document.title = "Fire Department";
  const fireuser = useSelector((store) => store.fireuser);

  return (
    <>
      <div className="row ">
        <FireDeparmentSidebar role="Fire Department" />
        <div className="container">
          {fireuser ? <Outlet /> : <Navigate to="/fire-department" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default FireDepartmentContainer;
