import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PwdDeparmentSidebar from "../Pwddepartment/PwdDepartmentSidebar";
// import { Outlet } from "react-router-dom";

const PwdContainer = () => {
  document.title = "MSEDCL Department";
  const pwduser = useSelector((store) => store.pwduser);

  return (
    <>
      <div className="row ">
        <PwdDeparmentSidebar role="PWD Department" />
        <div className="container">
          {pwduser ? <Outlet /> : <Navigate to="/pwd-department" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default PwdContainer;
