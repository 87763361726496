import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../common";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const swalalert = withReactContent(Swal);

const initialValues = {
  name: "",
  mobile: "",
  email: "",
  address: "",
  festival_name: "",
  mandal_name: "",
  mandal_address: "",
  mandal_registration_number: "",
  mandap_address: "",
  mandap_length: "",
  mandap_width: "",
  // mandap_area: "",
  mandap_duration: "",
  mandap_startdate: "",
  mandap_remove_date: "",
  kaman_address: "",
  kaman_number: "",
  kaman_height: "",
  // number_of_banner: "",
  complaints_against_applicant: "",
  permission_obtained_previously: "",
  // loudspeaker_approval: "",
  fire_prevention_measures: "",
  mandap_capacity_footpath_width: "",
  road_footpath_clear_citizens: "",
  department_divisional_office: "",
  police_department: "",
  // traffic_department: "",
  // fire_department: "",
  adharcard: null,
  member_list_mobile: null,
  // traffic_declaration: null,
  site_location_map: null,
};

const PermissionForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCheckboxChange1 = (e) => {
    setIsChecked(e.target.checked);
  };

  const formik = useFormik({
    initialValues: initialValues, // Ensure this is defined
    validationSchema: Yup.object({
      name: Yup.string().required("पूर्ण नाव प्रविष्ट करा."),
      email: Yup.string().required("ई-मेल आयडी प्रविष्ट करा."),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "कृपया वैध भारतीय मोबाइल नंबर प्रविष्ट करा.")
        .required("मोबाइल नंबर प्रविष्ट करा."),
      address: Yup.string().required("पत्ता प्रविष्ट करा."),
      festival_name: Yup.string().required("सणाचे नाव प्रविष्ट करा."),
      mandal_name: Yup.string().required("मंडळाचे नाव प्रविष्ट करा."),
      mandal_address: Yup.string().required("मंडळाचा पत्ता प्रविष्ट करा."),
      mandal_registration_number: Yup.string(),
      mandap_address: Yup.string().required("मंडपाचा पत्ता प्रविष्ट करा."),
      mandap_length: Yup.number()
        .typeError("कृपया वैध संख्या प्रविष्ट करा.") // Error when the input is not a number
        .required("मंडपाची लांबी प्रविष्ट करा.")
        .positive("मंडपाची लांबी सकारात्मक संख्या असावी.")
        .integer("मंडपाची लांबी पूर्णांक असावी."),

      mandap_width: Yup.number()
        .typeError("कृपया वैध संख्या प्रविष्ट करा.") // Error when the input is not a number
        .required("मंडपाची रुंदी प्रविष्ट करा.")
        .positive("मंडपाची रुंदी सकारात्मक संख्या असावी.")
        .integer("मंडपाची रुंदी पूर्णांक असावी."),

      // mandap_area: Yup.string().required("मंडपाचे क्षेत्रफळ प्रविष्ट करा."),
      mandap_duration: Yup.number()
        .max(15, "मंडपाचा कालावधी 15 दिवसांपेक्षा जास्त नसावा.")
        .required("मंडपाचा कालावधी प्रविष्ट करा."),
      mandap_startdate: Yup.string().required(
        "मंडप बांधण्याची प्रारंभ तारीख प्रविष्ट करा."
      ),
      mandap_remove_date: Yup.string().required(
        "मंडप काढण्याची अंतिम तारीख प्रविष्ट करा."
      ),
      kaman_address: Yup.string(),
      kaman_number: Yup.string(),
      kaman_height: Yup.string(),
      permission_obtained_previously: Yup.string(),
      complaints_against_applicant: Yup.string(),
      fire_prevention_measures: Yup.string().required(
        "अग्निसुरक्षा उपाय निवडा."
      ),
      mandap_capacity_footpath_width: Yup.string().required(
        "फूटपाथच्या रुंदीचे मोजमाप निवडा."
      ),
      road_footpath_clear_citizens: Yup.string().required(
        "रस्ता व फूटपाथ नागरिकांसाठी स्वच्छ असल्याचे निवडा."
      ),
      department_divisional_office: Yup.string().required(
        "विभागीय कार्यालय निवडा."
      ),
      police_department: Yup.string().required("पोलीस विभाग निवडा."),
      adharcard: Yup.mixed()
        .required("कृपया आधार कार्डची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      member_list_mobile: Yup.mixed()
        .required("कृपया सदस्य यादी निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      site_location_map: Yup.mixed()
        .required("कृपया साइट स्थान नकाशा निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
    }),
    onSubmit: (values, action) => {
      // Validate images manually before submission
      if (!values.adharcard) {
        toast.error("Please select an Aadhar Card image");
        return;
      }
      if (!values.member_list_mobile) {
        toast.error("Please select a Member List Mobile image");
        return;
      }
      if (!values.site_location_map) {
        toast.error("Please select a Site Location Map image");
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Permission?",
          text: `Confirm Permission Submission?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          if (swalObject.isConfirmed) {
            server
              .post(`/user_appl/userapplication`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Please Save This Application Id To Track Your Application Status ${response.data.id}`,
                    icon: "success",
                  });
                  formik.resetForm();
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              });
          }
        });
    },
  });

  useEffect(() => {
    if (formik.values.mandap_startdate && formik.values.mandap_duration) {
      const startDate = new Date(formik.values.mandap_startdate);
      const duration = parseInt(formik.values.mandap_duration, 10);

      if (!isNaN(duration)) {
        // Calculate the removal date
        const removeDate = new Date(startDate);
        removeDate.setDate(startDate.getDate() + duration);
        formik.setFieldValue(
          "mandap_remove_date",
          removeDate.toISOString().split("T")[0]
        );
      }
    }
  }, [formik.values.mandap_startdate, formik.values.mandap_duration]);

  return (
    <div>
      {console.log(formik.values)}
      {console.log(formik.errors)}

      <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          Festival Permission Application Form
        </h3>
      </div>
      <div className="container mt-4">
        <form onSubmit={formik.handleSubmit}>
          {/* अर्जदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">अर्जदाराची माहिती</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    अर्जदाराचे नांव:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="अर्जदाराचे नांव:"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name ? (
                    <p className="text-danger">{formik.errors.name}</p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    मोबाईल क्रमांक:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    placeholder="मोबाईल क्रमांक:"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mobile ? (
                    <p className="text-danger">{formik.errors.mobile}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    ई-मेल:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="ई-मेल:"
                  />
                  {formik.errors.email ? (
                    <p className="text-danger">{formik.errors.email}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    संपूर्ण पत्ता:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    placeholder="संपूर्ण पत्ता:"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.address ? (
                    <p className="text-danger">{formik.errors.address}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* उत्सव / सणाची माहिती */}
          <div className="card mb-3">
            <div className="card-header">उत्सव / सणाची माहिती</div>
            <div className="card-body row">
              <div className="form-group">
                <label>
                  उत्सव / सणाचे नांव:<span className="text-danger">*</span>
                </label>
                <select
                  name="festival_name"
                  value={formik.festival_name}
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="--Select उत्सव / सणाचे नांव--"
                >
                  <option>--Select उत्सव / सणाचे नांव--</option>
                  <option value="ganesh_utsav">गणेश उत्सव</option>
                  <option value="navratri">नवरात्री</option>
                  <option value="shiv_jayanti">शिवजयंती</option>
                  <option value="other">इतर </option>

                  {/* Add options here */}
                </select>
              </div>
              {formik.errors.festival_name ? (
                <p className="text-danger">{formik.errors.festival_name}</p>
              ) : null}
            </div>
          </div>

          {/* मंडळाचा तपशील */}
          <div className="card mb-3">
            <div className="card-header">मंडळाचा तपशील</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-4">
                  <label>
                    मंडळाचे नांव:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="mandal_name"
                    className="form-control"
                    placeholder="मंडळाचे नांव:"
                    value={formik.values.mandal_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandal_name ? (
                    <p className="text-danger">{formik.errors.mandal_name}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-4">
                  <label>
                    सार्वजनिक मंडळाचा पत्ता:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="mandap_address"
                    className="form-control"
                    placeholder="सार्वजनिक मंडळाचा पत्ता:"
                    value={formik.values.mandap_address}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandap_address ? (
                    <p className="text-danger">
                      {formik.errors.mandap_address}
                    </p>
                  ) : null}
                </div>
                <div className="form-group col-md-4">
                  <label>मंडळ नोंदणी क्रमांक (असल्यास):</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mandal_registration_number"
                    placeholder="मंडळ नोंदणी क्रमांक (असल्यास):"
                    value={formik.values.mandal_registration_number}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandal_registration_number ? (
                    <p className="text-danger">
                      {formik.errors.mandal_registration_number}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* ईतर माहिती */}
          <div className="card mb-3">
            <div className="card-header">ईतर माहिती</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    मंडपाची लांबी (फुट):<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="mandap_length"
                    className="form-control"
                    placeholder="मंडपाची लांबी (फुट):"
                    value={formik.values.mandap_length}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandap_length ? (
                    <p className="text-danger">{formik.errors.mandap_length}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    मंडपाची रुंदी (फुट):<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mandap_width"
                    placeholder="मंडपाची रुंदी (फुट):"
                    value={formik.values.mandap_width}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandap_width ? (
                    <p className="text-danger">{formik.errors.mandap_width}</p>
                  ) : null}
                </div>
                {/*<div className="form-group col-md-3">
                  <label>
                    मंडपाचे क्षेत्रफळ (चौरस फूट):
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mandap_area"
                    placeholder="मंडपाचे क्षेत्रफळ:"
                    value={formik.values.mandap_area}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandap_area ? (
                    <p className="text-danger">{formik.errors.mandap_area}</p>
                  ) : null}
                </div>*/}
                <div className="form-group col-md-3">
                  <label>
                    मंडप उभारण्यात येणार्‍या जागेचा पत्ता:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mandal_address"
                    placeholder="मंडप उभारण्यात येणार्‍या जागेचा पत्ता:"
                    value={formik.values.mandal_address}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandal_address ? (
                    <p className="text-danger">
                      {formik.errors.mandal_address}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    मंडपाचा कालावधी (दिवस):
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mandap_duration"
                    placeholder="मंडपाचा कालावधी (दिवस)"
                    value={formik.values.mandap_duration}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.mandap_duration ? (
                    <p className="text-danger">
                      {formik.errors.mandap_duration}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    मंडप बांधकाम सुरू करण्याचा दिनांक:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="mandap_startdate"
                    placeholder="मंडप बांधकाम सुरू करणायचा दिनांक:"
                    value={formik.values.mandap_startdate}
                    onChange={formik.handleChange}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  {formik.errors.mandap_startdate ? (
                    <p className="text-danger">
                      {formik.errors.mandap_startdate}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    मंडप काढण्याचा अंतिम दिनांक:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="mandap_remove_date"
                    placeholder="मंडप काढण्याचा अंतिम दिनांक:"
                    value={formik.values.mandap_remove_date}
                    onChange={formik.handleChange}
                    min={new Date().toISOString().split("T")[0]}
                    disabled // Disable manual editing
                  />
                  {formik.errors.mandap_remove_date ? (
                    <p className="text-danger">
                      {formik.errors.mandap_remove_date}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>कमान उभारण्याचे ठिकाण:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="kaman_address"
                    placeholder="कमान उभारण्याचे ठिकाण:"
                    value={formik.values.kaman_address}
                    onChange={formik.handleChange}
                  />
                  {/* {formik.errors.kaman_address ? (
                    <p className="text-danger">{formik.errors.kaman_address}</p>
                  ) : null} */}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>कमानाची संख्या:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="कमानाची संख्या:"
                    name="kaman_number"
                    value={formik.values.kaman_number}
                    onChange={formik.handleChange}
                  />
                  {/* {formik.errors.kaman_number ? (
                    <p className="text-danger">{formik.errors.kaman_number}</p>
                  ) : null} */}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    कमानाची ऊंची ( अग्निशमन बंब जाईल अशी ठेवणे ) (फुट):
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="kaman_height"
                    placeholder="कमानाची ऊंची ( अग्निशमन बंब जाईल अशी ठेवणे ) (फुट):"
                    value={formik.values.kaman_height}
                    onChange={formik.handleChange}
                  />
                  {/* {formik.errors.kaman_height ? (
                    <p className="text-danger">{formik.errors.kaman_height}</p>
                  ) : null} */}
                </div>

                {/* <div className="form-group my-3 col-md-3">
                  <label>बॅनर संख्या:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="बॅनर संख्या:"
                    name="number_of_banner"
                    value={formik.values.number_of_banner}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.number_of_banner ? (
                    <p className="text-danger">
                      {formik.errors.number_of_banner}
                    </p>
                  ) : null}
                </div> */}

                <div className="form-group my-3 col-md-3">
                  <label>
                    यापूर्वी अर्जदाराविरुद्ध तक्रार असल्यास त्याचा तपशील:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="complaints_against_applicant"
                    placeholder="यापूर्वी अर्जदाराविरुद्ध तक्रार असल्यास त्याचा तपशील:"
                    value={formik.values.complaints_against_applicant}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.complaints_against_applicant ? (
                    <p className="text-danger">
                      {formik.errors.complaints_against_applicant}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    यापूर्वी परवानगी प्राप्त केली असल्यास त्याचा तपशील:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="permission_obtained_previously"
                    placeholder="यापूर्वी परवानगी प्राप्त केली असल्यास त्याचा तपशील:"
                    value={formik.values.permission_obtained_previously}
                    onChange={formik.handleChange}
                  />
                  {/* {formik.errors.permission_obtained_previously ? (
                    <p className="text-danger">
                      {formik.errors.permission_obtained_previously}
                    </p>
                  ) : null} */}
                </div>

                {/* <div className="form-group my-3 col-md-4">
                  <label>
                    अर्जदाराने लाऊड स्पीकरची परवानगी प्राप्त केली आहे का?
                    <span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="loudspeaker_approval"
                        id="loudspeakerYes"
                        value="Yes"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerYes"
                      >
                        होय
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="loudspeaker_approval"
                        id="loudspeakerNo"
                        value="No"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerNo"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.loudspeaker_approval ? (
                    <p className="text-danger">
                      {formik.errors.loudspeaker_approval}
                    </p>
                  ) : null}
                </div> */}

                <div className="form-group my-3 col-md-4">
                  <label>
                    आग प्रतिबंधक उपाय योजना निकषांची अंमलबजावणी करण्यात आली आहे
                    का ?<span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="fire_prevention_measures"
                        id="loudspeakerYes"
                        value="yes"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerYes"
                        onChange={formik.handleChange}
                      >
                        होय
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="fire_prevention_measures"
                        id="loudspeakerNo"
                        value="no"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerNo"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.fire_prevention_measures ? (
                    <p className="text-danger">
                      {formik.errors.fire_prevention_measures}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    बांधण्याात उभारण्यात येणार्‍या मंडपात येणार्‍या संभावित
                    लोकांना सामावण्याची क्षमता तसेच रस्ता / फुटपाथची रुंदी
                    पुरेशी आहे का ? <span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mandap_capacity_footpath_width"
                        id="loudspeakerYes"
                        value="Yes"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="mandap_capacity_footpath_width"
                      >
                        होय
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="mandap_capacity_footpath_width"
                        id="loudspeakerNo"
                        value="No"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="mandap_capacity_footpath_width"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.mandap_capacity_footpath_width ? (
                    <p className="text-danger">
                      {formik.errors.mandap_capacity_footpath_width}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    नागरीकांना जाण्या-येण्यासाठी पुरेसा रास्ता / फुटपाथ मोकळा
                    आहे का ? <span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="road_footpath_clear_citizens"
                        id="loudspeakerYes"
                        value="yes"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerYes"
                      >
                        होय
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="road_footpath_clear_citizens"
                        id="loudspeakerNo"
                        value="no"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerNo"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.road_footpath_clear_citizens ? (
                    <p className="text-danger">
                      {formik.errors.road_footpath_clear_citizens}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* विभाग
           */}
          <div className="card mb-3">
            <div className="card-header">विभाग</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group my-3 col-md-3">
                  <label>
                    प्रभाग कार्यालय : <span className="text-danger">*</span>
                  </label>
                  <select
                    name="department_divisional_office"
                    className="form-control"
                    value={formik.values.department_divisional_office}
                    onChange={formik.handleChange}
                  >
                    <option value="">--Select प्रभाग कार्यालय--</option>
                    <option value="सावेडी">प्रभाग समिती क्र. ०१ सावेडी</option>
                    <option value="वसुली">
                      प्रभाग समिती क्र. ०२ शहर वसुली
                    </option>
                    <option value="झेंडीगेट">
                      प्रभाग समिती क्र. ०३ झेंडीगेट
                    </option>
                    <option value="बुरुडगांव">
                      प्रभाग समिती क्र. ०४ बुरुडगांव
                    </option>

                    {/* Add options here */}
                  </select>

                  {formik.errors.department_divisional_office ? (
                    <p className="text-danger">
                      {formik.errors.department_divisional_office}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    पोलीस स्टेशन / ठाणे : <span className="text-danger">*</span>
                  </label>

                  <select
                    value={formik.values.police_department}
                    onChange={formik.handleChange}
                    name="police_department"
                    className="form-control"
                  >
                    <option value="">--Select पोलीस स्टेशन / ठाणे--</option>
                    <option value="तोफखाना पोलिस स्टेशन">
                      तोफखाना पोलिस स्टेशन
                    </option>
                    <option value="कोतवाली पोलिस स्टेशन">
                      कोतवाली पोलिस स्टेशन
                    </option>
                    <option value="एम. आय. डी.सी. पोलिस स्टेशन">
                      एम. आय. डी.सी. पोलिस स्टेशन
                    </option>
                    <option value="भिंगार कॅम्प पोलिस स्टेशन">
                      भिंगार कॅम्प पोलिस स्टेशन
                    </option>
                    <option value="तालुका पोलीस स्टेशन">
                      तालुका पोलीस स्टेशन
                    </option>
                  </select>
                  {formik.errors.police_department ? (
                    <p className="text-danger">
                      {formik.errors.police_department}
                    </p>
                  ) : null}
                </div>

                {/* <div className="form-group my-3 col-md-3">
                  <label>
                    शहर वाहतूक विभाग : <span className="text-danger">* </span>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    name="traffic_department"
                    value={formik.traffic_department}
                    onChange={formik.handleChange}
                    placeholder="शहर वाहतूक विभाग "
                  />
                  {formik.errors.traffic_department ? (
                    <p className="text-danger">
                      {formik.errors.traffic_department}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-3">
                  <label>
                    अग्निशमन केंद्र (फायर स्टेशन ) :
                    <span className="text-danger">* </span>
                  </label>

                  <input
                    type="text"
                    name="fire_department"
                    value={formik.fire_department}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="अग्निशमन केंद्र (फायर स्टेशन )"
                  />
                  {formik.errors.fire_department ? (
                    <p className="text-danger">
                      {formik.errors.fire_department}
                    </p>
                  ) : null}
                </div> */}
              </div>
            </div>
          </div>

          {/* <div className="card">
            <div className="card-header">
              <h5 className="card-header">Download Details</h5>
            </div>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                <strong>1. Declaration Form</strong>
                <button className="btn btn-primary">
                  <a
                    className="text-decoration-none text-white"
                    href="/Hamipatra_Festival_Permission.pdf"
                    target="_blank"
                  >
                    Download
                  </a>
                </button>
              </li>
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                <strong> 2. Paripatrak 2020</strong>
                <button className="btn btn-primary">Download</button>
              </li>
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                <strong> 3. जाहिर सूचना</strong>
                <button className="btn btn-primary">Download</button>
              </li>
            </ul>
          </div> */}

          {/* Document Upload Section */}
          <div className="card mt-4">
            <div className="card-header">
              <h5 className="card-header">जोडण्याची कागदपत्रे</h5>
            </div>
            <div className="card-body ">
              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    आधार कार्ड(pdf, jpg, jpeg):
                    <span className="text-danger">*</span>
                  </label>
                  <br />
                  <input
                    type="file"
                    name="adharcard"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "adharcard",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {/* <small className="form-text text-muted">
                    (Upload document in pdf, jpg, jpeg format)
                  </small> */}

                  {formik.errors.adharcard ? (
                    <p className="text-danger">{formik.errors.adharcard}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-6">
                  <label>
                    मंडळातील पदाधिकारी / सदस्यांची यादी व मोबाइल क्रमांक(pdf,
                    jpg, jpeg): <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="member_list_mobile"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "member_list_mobile",
                        event.currentTarget.files[0]
                      );
                    }}
                  />

                  {formik.errors.member_list_mobile ? (
                    <p className="text-danger">
                      {formik.errors.member_list_mobile}
                    </p>
                  ) : null}
                </div>
                {/* <div className="form-group col-md-6">
                  <label>
                    ट्रॅफिक - हमीपत्र (नमुना म्हधे)(pdf, jpg, jpeg):
                    <span className="text-danger">*</span>
                  </label>
                  <br />
                  <input
                    type="file"
                    name="traffic_declaration"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "traffic_declaration",
                        event.currentTarget.files[0]
                      );
                    }}
                  />

                  {formik.errors.traffic_declaration ? (
                    <p className="text-danger">
                      {formik.errors.traffic_declaration}
                    </p>
                  ) : null}
                </div> */}
                <div className="form-group col-md-6">
                  <label>
                    मंडपाचा स्थल दर्शक नकाशा(pdf, jpg, jpeg):
                    <span className="text-danger">*</span>
                  </label>
                  <br />
                  <input
                    type="file"
                    name="site_location_map"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "site_location_map",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {/* <small className="form-text text-muted">
                    (Upload document in pdf, jpg, jpeg format)
                  </small> */}
                  {formik.errors.site_location_map ? (
                    <p className="text-danger">
                      {formik.errors.site_location_map}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header ">
              <h5 className="card-header">अर्जदाराचे हमीपत्र</h5>
            </div>
            <div className="card-body">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terms"
                  onChange={handleCheckboxChange1}
                />
                <label className="form-check-label " htmlFor="terms">
                  वरीप्रमाणे, आयोजित कार्यक्रमासाठी मंडप/स्टेज उभारणार आहे.
                  मंडप/स्टेज उभारताना मा. उच्च न्यायालय, मुंबई व अहमदनगर
                  महानगरपालिका यांचे सर्व नियम व मार्गदर्शक तत्वांचे पालन करेन.
                  मंडप/स्टेज मुळे वाहतुकीस अथवा रहदारीस अडथळा निर्माण होणार
                  नाही, याची पूर्ण जबाबदारी माझी राहील. कार्यक्रम संपल्यानंतर
                  मंडप/स्टेज त्वरित काढण्यात येईल. सदर अटी व शर्तीचे उल्लंघन
                  झाल्यास मी कायदेशीर कारवाईस पात्र राहील.
                  <span className="text-danger">*</span>
                </label>
              </div>
            </div>
          </div>

          {/* Terms and Conditions Section */}
          <div className="card mt-4">
            <div className="card-header ">
              <h5 className="card-header">अटी व शर्ती</h5>
            </div>
            <div className="card-body">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label " htmlFor="termsCheck">
                  याबद्दल असे जाहीर करतो / करते की, अहमदनगर महानगरपालिकेच्या
                  मंडप/स्टेज उभारणे बाबतच्या मार्गदर्शन तत्त्वानुसार जनहित
                  याचिका क्रमांक 173/2010 व 155/2011 यामधील मा उच्च न्यायालयाने
                  दिलेल्या आदेशानुसार कार्यक्रम पार पाडण्यात येईल. कुठल्याही अटी
                  - शर्ती भंग झाल्यास या बाबतची सर्व जबाबदारी मंडळाची राहील.
                  <span className="text-danger">*</span>
                </label>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="btn btn-primary  mt-2"
            // disabled={!isChecked && !isChecked1}
            disabled
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PermissionForm;
