import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../common";

const initialValues = {
  name: "",
  mobile: "",
  email: "",
  address: "",
  amc_divisional_office: "",
  police_station: "",
  traffice_office: "",
  fire_station: "",
  shop_name: "",
  shop_owner_name: "",
  shop_adderess: "",
  applicant: "",
  appl_type: "",
  murti_type: "",
  registration: "",
  start_date: "",
  last_date: "",
  place_height: "",
  fire_measures_implemented: "",
  pathway_clear: "",
  place: "",
  place_type: "",
  adharcard: null,
  affidavit_type: null,
  place_owner_noc: null,
  property_tax_receipt: null,
};

const MurtikarForm = () => {
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Enter a Fullname"),
      email: Yup.string().required("Enter a Email Id"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian mobile number")
        .required("Enter a  Mobile No."),
      address: Yup.string().required("Enter Address"),
      amc_divisional_office: Yup.string().required("Enter Festival Name"),
      police_station: Yup.string().required("Enter Festival Name"),
      traffice_office: Yup.string().required("Enter Mandal Name"),
      fire_station: Yup.string().required("Enter Mandal Address"),
      shop_name: Yup.string().required("Enter Mandal Registration Number"),
      shop_owner_name: Yup.string().required("Enter Mandap Length"),
      shop_adderess: Yup.string().required("Enter Mandap Length"),
      applicant: Yup.string().required("Enter Mandap Width"),
      appl_type: Yup.string().required("Enter Mandap Area"),
      murti_type: Yup.string().required("Enter Mandap Duration"),
      registration: Yup.string().required("Enter Mandal Start Date"),
      start_date: Yup.string().required("Enter Mandal End Date"),
      last_date: Yup.string().required("Enter Kaman Address"),
      place_height: Yup.string().required("Enter Kaman Number"),
      fire_measures_implemented: Yup.string().required("Enter Kaman Height"),
      pathway_clear: Yup.string().required("Enter Number Of Banner"),
      place: Yup.string().required("Enter Comlaint Against Application"),
      place_type: Yup.string().required("Enter Comlaint Against Application"),
      adharcard: Yup.mixed()
        .required("Please select an image")
        .test("fileSize", "File size is too large", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      affidavit_type: Yup.mixed()
        .required("Please select an image")
        .test("fileSize", "File size is too large", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      place_owner_noc: Yup.mixed()
        .required("Please select an image")
        .test("fileSize", "File size is too large", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      property_tax_receipt: Yup.mixed()
        .required("Please select an image")
        .test("fileSize", "File size is too large", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
    }),
    onSubmit: (values, action) => {
      if (!values.adharcard) {
        toast.error("Please select an Aadhar Card image");
        return;
      }
      if (!values.affidavit_type) {
        toast.error("Please select a Member List Mobile image");
        return;
      }
      if (!values.place_owner_noc) {
        toast.error("Please select a Traffic Declaration image");
        return;
      }
      if (!values.property_tax_receipt) {
        toast.error("Please select a Site Location Map image");
        return;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("mobile", values.mobile);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("amc_divisional_office", values.amc_divisional_office);
      formData.append("traffice_office", values.traffice_office);
      formData.append("fire_station", values.fire_station);
      formData.append("shop_name", values.shop_name);
      formData.append("shop_owner_name", values.shop_owner_name);
      formData.append("shop_adderess", values.shop_adderess);
      formData.append("applicant", values.applicant);
      formData.append("appl_type", values.appl_type);
      formData.append("murti_type", values.murti_type);
      formData.append("registration", values.registration);
      formData.append("start_date", values.start_date);
      formData.append("last_date", values.last_date);
      formData.append("place_height", values.place_height);
      formData.append(
        "fire_measures_implemented",
        values.fire_measures_implemented
      );
      formData.append("place", values.place);
      formData.append("place_type", values.place_type);
      formData.append("adharcard", values.adharcard);
      formData.append("affidavit_type", values.affidavit_type);
      formData.append("place_owner_noc", values.place_owner_noc);
      formData.append("property_tax_receipt", values.property_tax_receipt);

      server
        .post("/murticar/murticarapplication", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            toast.success(response.data.message);
            formik.resetForm();
          }
        })
        .catch(function (error) {
          if (error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
        });
    },
  });

  return (
    <div>
      {console.log(formik.values)}
      {console.log(formik.errors)}

      <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          मूर्तिकार/स्टॉकिस्ट परवानगी/मूर्ति विक्रेता (Murtikar/Stockist/Murti
          Saler Permission)
        </h3>
      </div>

      <div className="container mt-4">
        <form onSubmit={formik.handleSubmit}>
          {/* अर्जदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">अर्जदाराची माहिती</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    अर्जदाराचे नांव:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="अर्जदाराचे नांव:"
                  />
                  {formik.errors.name ? (
                    <p className="text-danger">{formik.errors.name}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    मोबाईल क्रमांक:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="मोबाईल क्रमांक:"
                  />
                  {formik.errors.mobile ? (
                    <p className="text-danger">{formik.errors.mobile}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    ई-मेल:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="ई-मेल:"
                  />
                  {formik.errors.email ? (
                    <p className="text-danger">{formik.errors.email}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    संपूर्ण पत्ता:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="संपूर्ण पत्ता:"
                  />
                  {formik.errors.address ? (
                    <p className="text-danger">{formik.errors.address}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/*विभाग */}
          <div className="card mb-3">
            <div className="card-header">विभाग</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group my-3 col-md-3">
                  <label>
                    प्रभाग कार्यालय : <span className="text-danger">*</span>
                  </label>
                  <select
                    name="amc_divisional_office"
                    className="form-control"
                    value={formik.values.amc_divisional_office}
                    onChange={formik.handleChange}
                  >
                    <option>--Select प्रभाग कार्यालय--</option>
                    <option value="सावेडी">प्रभाग समिती क्र. ०१ सावेडी</option>
                    <option value="वसुली">
                      प्रभाग समिती क्र. ०२ शहर वसुली{" "}
                    </option>
                    <option value="झेंडीगेट">
                      प्रभाग समिती क्र. ०३ झेंडीगेट{" "}
                    </option>
                    <option value="बुरुडगांव">
                      प्रभाग समिती क्र. ०४ बुरुडगांव{" "}
                    </option>

                    {/* Add options here */}
                  </select>

                  {formik.errors.amc_divisional_office ? (
                    <p className="text-danger">
                      {formik.errors.amc_divisional_office}
                    </p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    पोलीस स्टेशन / ठाणे : <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="police_station"
                    value={formik.values.police_station}
                    onChange={formik.handleChange}
                  >
                    <option>--Select पोलीस स्टेशन / ठाणे--</option>
                    <option value="तोफखाना पोलिस स्टेशन">
                      तोफखाना पोलिस स्टेशन
                    </option>
                    <option value="कोतवाली पोलिस स्टेशन">
                      कोतवाली पोलिस स्टेशन
                    </option>
                    <option value="एम. आय. डी.सी. पोलिस स्टेशन">
                      एम. आय. डी.सी. पोलिस स्टेशन{" "}
                    </option>
                    <option value="भिंगार कॅम्प पोलिस स्टेशन">
                      भिंगार कॅम्प पोलिस स्टेशन
                    </option>

                    {/* Add options here */}
                  </select>
                  {formik.errors.police_department ? (
                    <p className="text-danger">
                      {formik.errors.police_department}
                    </p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    शहर वाहतूक विभाग :<span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="traffice_office"
                    value={formik.values.traffice_office}
                    onChange={formik.handleChange}
                    placeholder="शहर वाहतूक विभाग:"
                  />
                  {formik.errors.traffice_office ? (
                    <p className="text-danger">
                      {formik.errors.traffice_office}
                    </p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    अग्निशमन केंद्र (फायर स्टेशन):{" "}
                    <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="fire_station"
                    value={formik.values.fire_station}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="अग्निशमन केंद्र:
"
                  />
                  {formik.errors.fire_station ? (
                    <p className="text-danger">{formik.errors.fire_station}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* अर्जीदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">
              मूर्तिकार / साठवणूकदार / कारखानदार / विक्रेता गाळ्याची / जागेची
              माहिती
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    गाळ्याचे नाव: <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="shop_name"
                    value={formik.values.shop_name}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="गाळ्याचे नाव : 
"
                  />
                  {formik.errors.shop_name ? (
                    <p className="text-danger">{formik.errors.shop_name}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    गाळाधारकाचे नाव : <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="shop_owner_name"
                    value={formik.values.shop_owner_name}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="गाळाधारकाचे नाव : 
"
                  />
                  {formik.errors.shop_owner_name ? (
                    <p className="text-danger">
                      {formik.errors.shop_owner_name}
                    </p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    गाळ्याच्या जागेचा पत्ता :{" "}
                    <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="shop_adderess"
                    value={formik.values.shop_adderess}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="गाळ्याच्या जागेचा पत्ता
"
                  />
                  {formik.errors.shop_adderess ? (
                    <p className="text-danger">{formik.errors.shop_adderess}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* अर्जीदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">
              मूर्तिकार / साठवणूकदार / कारखानदार / विक्रेता तपशील
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    अर्जदार : <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="applicant"
                    value={formik.values.applicant}
                    onChange={formik.handleChange}
                  >
                    <option value="" selected="selected">
                      --Select अर्जदार: --
                    </option>
                    <option value="murti maker">मूर्तिकार (Murti Maker)</option>
                    <option value="stockist">साठवणूकदार(Stockist)</option>
                    <option value="factory owner">
                      कारखानदार (Factory Owner)
                    </option>
                    <option value="murti seller">विक्रेता (Murti Saler)</option>
                  </select>
                  {formik.errors.applicant ? (
                    <p className="text-danger">{formik.errors.applicant}</p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    अर्ज प्रकार : <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="appl_type"
                    value={formik.values.appl_type}
                    onChange={formik.handleChange}
                  >
                    <option value="" selected="selected">
                      --Select अर्ज प्रकार:--
                    </option>
                    <option value="1">
                      (शाडू /पर्यावरणपूरक साहित्याने मूर्ती घडविणारे मूर्तिकार
                      परवानगी) (Eco Friendly Murtikar Permission)
                    </option>
                    <option value="2">
                      (शाडू /पर्यावरणपूरक साहित्याने मूर्ती घडविणारे मूर्तिकार
                      परवानगी) / (POP मूर्ती घडविणारे मूर्तिकार परवानगी) (Eco
                      Friendly/POP Murtikars Permission
                    </option>
                    <option value="3">
                      (मूर्त्या साठवणूकदारांचे / कारखानदारांचे हमीपत्र)/विक्री
                      करणारे / (Stockists/Factory/Saler )
                    </option>
                  </select>
                  {formik.errors.appl_type ? (
                    <p className="text-danger">{formik.errors.appl_type}</p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    मूर्तीचा प्रकार : <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    className="form-control"
                    name="murti_type"
                    value={formik.values.murti_type}
                    onChange={formik.handleChange}
                  >
                    <option value="" selected="selected">
                      --Select मूर्तीचा प्रकार: --
                    </option>
                    <option value="non pop">
                      शाडू /पर्यावरणपूरक मूर्ती (Non-POP)
                    </option>
                    <option value="pop">POP मूर्ती (POP)</option>
                    <option value="both">
                      शाडू /पर्यावरणपूरक मूर्ती / POP मूर्ती (Both)
                    </option>
                  </select>
                  {formik.errors.murti_type ? (
                    <p className="text-danger">{formik.errors.murti_type}</p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>नोंदणी क्रमांक (असल्यास ) :</label>

                  <input
                    type="text"
                    name="registration"
                    value={formik.values.registration}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="नोंदणी क्रमांक
"
                  />
                  {formik.errors.registration ? (
                    <p className="text-danger">{formik.errors.registration}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* अर्जीदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">
              मूर्तिकार / साठवणूकदार / कारखानदार / विक्रेता कालावधी
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    सुरू करणायचा दिनांक<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="सुरू करणायचा दिनांक
"
                  />
                  {formik.errors.start_date ? (
                    <p className="text-danger">{formik.errors.start_date}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    अंतिम दिनांक<span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    name="last_date"
                    value={formik.values.last_date}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="अंतिम दिनांक
"
                  />
                  {formik.errors.last_date ? (
                    <p className="text-danger">{formik.errors.last_date}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* अर्जीदाराची माहिती */}
          <div className="card mb-3">
            <div className="card-header">ईतर माहिती</div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    जागेची लांबी (फुट) : <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="place_height"
                    value={formik.values.place_height}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="जागेची लांबी (फुट) "
                  />
                  {formik.errors.place_height ? (
                    <p className="text-danger">{formik.errors.place_height}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    जागेची रुंदी (फुट) : <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="place_width"
                    value={formik.values.place_width}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="जागेची रुंदी (फुट) 
"
                  />
                  {formik.errors.place_width ? (
                    <p className="text-danger">{formik.errors.place_width}</p>
                  ) : null}
                </div>

                <div className="form-group col-md-3">
                  <label>
                    जागेचे क्षेत्रफळ : <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="place_area"
                    value={formik.values.place_area}
                    onChange={formik.handleChange}
                    className="form-control"
                    placeholder="जागेचे क्षेत्रफळ"
                  />
                  {formik.errors.place_area ? (
                    <p className="text-danger">{formik.errors.place_area}</p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    आग प्रतिबंधक उपाय योजना निकषांची अंमलबजावणी करण्यात आली आहे
                    का ?<span className="text-danger">*</span>:
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="fire_measures_implemented"
                        value="fire_measures_implemented"
                        name="fire_measures_implemented"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="fire_measures_implemented"
                      >
                        होय
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="fire_measures_implemented"
                        onChange={formik.handleChange}
                        id="loudspeakerNo"
                        value="fire_measures_implemented"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerNo"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.fire_measures_implemented ? (
                    <p className="text-danger">
                      {formik.errors.fire_measures_implemented}
                    </p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    नागरीकांना जाण्या-येण्यासाठी पुरेसा रास्ता / फुटपाथ मोकळा
                    आहे का ?:<span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="pathway_clear"
                        onChange={formik.handleChange}
                        id="loudspeakerYes"
                        value="pathway_clear"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pathway_clear"
                      >
                        होय
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="pathway_clear"
                        value="no"
                        name="pathway_clear"
                        onChange={formik.handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="pathway_clear"
                      >
                        नाही
                      </label>
                    </div>
                  </div>
                  {formik.errors.pathway_clear ? (
                    <p className="text-danger">{formik.errors.pathway_clear}</p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    जागा :<span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="place"
                        onChange={formik.handleChange}
                        id="place"
                        value="private"
                      />
                      <label className="form-check-label" htmlFor="place">
                        खाजगी
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="place"
                        onChange={formik.handleChange}
                        id="place"
                        value="municipal"
                      />
                      <label className="form-check-label" htmlFor="place">
                        महानगरपालिका
                      </label>
                    </div>
                  </div>
                  {formik.errors.place ? (
                    <p className="text-danger">{formik.errors.place}</p>
                  ) : null}
                </div>

                <div className="form-group my-3 col-md-4">
                  <label>
                    जागेचा प्रकार: <span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="place_type"
                        onChange={formik.handleChange}
                        value="shop"
                        id="loudspeakerYes"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerYes"
                      >
                        गाळा
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="loudspeakerNo"
                        name="place_type"
                        onChange={formik.handleChange}
                        value="open space"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="loudspeakerNo"
                      >
                        खुली जागा
                      </label>
                    </div>
                    {formik.errors.place_type ? (
                      <p className="text-danger">{formik.errors.place_type}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h5 className="card-header">हमीपत्र माहिती</h5>
            </div>
            <ul className="list-group list-group-flush card-body">
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                शाडू /पर्यावरणपूरक साहित्याने मूर्ती घडविणाऱ्या /विक्री करणाऱ्या
                मूर्तीकारांचे हमीपत्र
                <button className="btn btn-primary">Download</button>
              </li>
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                शाडू /पर्यावरणपूरक साहित्याने /विक्री करणारे /POP मूर्ती
                घडविणाऱ्या /विक्री करणारे मूर्तीकारांचे हमीपत्र
                <button className="btn btn-primary">Download</button>
              </li>
              <li
                className="list-group-item d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "#cdbf5c3b" }}
              >
                मूर्त्या साठवणूकदारांचे / कारखानदारांचे हमीपत्र
                <button className="btn btn-primary">Download</button>
              </li>
            </ul>
          </div>

          {/* Document Upload Section */}
          <div className="card mt-4">
            <div className="card-header">
              <h5 className="card-header">जोडण्याची कागदपत्रे</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>
                    आधार कार्ड(pdf, jpg, jpeg format):{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="adharcard"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "adharcard",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.errors.adharcard ? (
                    <p className="text-danger">{formik.errors.adharcard}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    हमीपत्र (मूर्तिकार / स्टॉकिस्ट अर्ज प्रकार नुसार)(pdf, jpg,
                    jpeg format) :<span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="affidavit_type"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "affidavit_type",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.errors.affidavit_type ? (
                    <p className="text-danger">
                      {formik.errors.affidavit_type}
                    </p>
                  ) : null}
                </div>
                <br></br>
                <div className="form-group col-md-3">
                  <label>
                    जागा मालकाची NOC(pdf, jpg, jpeg format):{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="place_owner_noc"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "place_owner_noc",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.errors.place_owner_noc ? (
                    <p className="text-danger">
                      {formik.errors.place_owner_noc}
                    </p>
                  ) : null}
                </div>
                <div className="form-group col-md-3">
                  <label>
                    मालमत्ता कर पावती (property tax receipt) (pdf, jpg, jpeg
                    format):
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="property_tax_receipt"
                    className="form-control-file"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "property_tax_receipt",
                        event.currentTarget.files[0]
                      );
                    }}
                  />
                  {formik.errors.property_tax_receipt ? (
                    <p className="text-danger">
                      {formik.errors.property_tax_receipt}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Terms and Conditions Section */}
          <div className="card mt-4">
            <div className="card-header">
              <h5 className="card-header">अटी व शर्ती</h5>
            </div>
            <div className="card-body">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                />
                <label className="form-check-label" htmlFor="termsCheck">
                  मी याद्वारे जाहिर करतो/ करते की, मला याबाबत वेगवेगळे अधिनियम,
                  नियम व मा. उच्च न्याायालयाच्या विविध निर्देशांची जाणीव आहे. मी
                  याद्वारे असेही जाहिर करतो की, मी वर दिलेली माहिती माझ्या
                  समजुतीप्रमाणे खरी व बिनचूक आहे आणि उपरोक्त माहिती चुकीची /
                  खोटी आढळल्यास माझ्यावर कायदेशीर कारवाई करण्यात येईल याची मला
                  जाणीव आहे. I hereby declare that I am aware of different laws,
                  rules, regulations and Hon'ble High Court's orders in this
                  regard.I also declare that the information submitted by me is
                  true and correct to the best of my knowledge And I am aware
                  that if it is found incorrect, I will be liable for penal
                  action/punishment as per law.
                  <span className="text-danger">*</span>
                </label>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn-primary  mt-2">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default MurtikarForm;
