import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PermissionForm from './pages/permissionform/PermissionForm'
import Navbar from './components/navbar/Navbar'
import DownLoad from './pages/download/DownLoad'
import TrackApplication from './pages/trackapplication/TrackApplication'
import SuperAdminContainer from './pages/containers/SuperAdminContainer'
import Dashboard from './pages/superadmin/Dashboard'
import PendingApplications from './pages/Administration/PendingApplications'
import ApprovedApplications from './pages/Administration/ApprovedApplications'
import TotalApplications from './pages/Administration/TotalApplications'
import RejectedApplications from './pages/Administration/RejectedApplications'
import HomepageContainer from './pages/containers/HomeContainer'
import DivisionWiseReport from './pages/Administration/DivisionWiseReport'
import MurtikarForm from './pages/murtiPermission/MurtikarForm'
import { ToastContainer } from 'react-toastify'
import { ToastConfig } from './config'
import SuperadminLogin from './pages/superadmin/SuperadminLogin'
import DivisionalOfficerContainer from './pages/containers/DivisionalOffContainer'
import DivisionalOfficerLogin from './pages/divisionalofficer/DivisionalOfficerLogin'
import DivisionTotalApplication from './pages/divisionalofficer/DivisionTotalApplication'
import TrafficLogin from './pages/traffic-department/TrafficLogin'
import TrafficPoliceContainer from './pages/containers/TrafficPoliceContainer'
import TrafficTotalApplications from './pages/traffic-department/TrafficTotalApplications'
import SuperAdminTotalApplications from './pages/superadmin/SuperAdminTotalApplications'
import TotalMurticarApplications from './pages/Administration/TotalMurticarApplications'
import SuperAdminMurtiTotalAppl from './pages/superadmin/SuperAdminMurtiTotalAppl/SuperAdminMurtiTotalAppl'
import FireDepartmentContainer from './pages/containers/FireDepartmentContainer'
import FireDepartmentLogin from './pages/firedepartment/FireDepartmentLogin'
import FireDepartmentDashboard from './pages/firedepartment/FireDepartmentDashboard'
import FireTotalApplications from './pages/firedepartment/FireTotalApplications'
import PoliceDepartmentLogin from './pages/policeDeparment/PoliceDepartmentLogin'
import PoliceDepartmentContainer from './pages/containers/PoliceContainer'
import PoliceTotalApplications from './pages/policeDeparment/PoliceTotalApplications'
import PoliceDashoard from './pages/policeDeparment/PoliceDashboard'
import PwdDepartmentLogin from './pages/Pwddepartment/PwdDepartmentLogin'
import PwdContainer from './pages/containers/PwdContainer'
import PwdTotalApplications from './pages/Pwddepartment/PwdTotalApplications'
import PwdDashboard from './pages/Pwddepartment/PwdDashboard'
import SuperAdminApprovedPermissions from './pages/superadmin/SuperAdminApprovedPermissions'
import TrafficApprovedPermissions from './pages/traffic-department/TrafficApprovedPermissions'
import PoliceApprovedPermissions from './pages/policeDeparment/PoliceApprovedPermissions'
import FireApprovedPermissions from './pages/firedepartment/FireApprovedPermissions'
import DivisionApprovedPermissions from './pages/divisionalofficer/DivisionApprovedPermissions'
import TrafficDashboard from './pages/traffic-department/TrafficDashboard'
import DivisionDashboard from './pages/divisionalofficer/DivisionDashboard'
import DivisionRejectedPermissions from './pages/divisionalofficer/DivisionRejectedPermission'
import DivisionPendingPermissions from './pages/divisionalofficer/DivisionPendingPermission'
import PwdApprovedPermissions from './pages/Pwddepartment/PwdApprovedApplication'
import SuperAdminRejectedPermissions from './pages/superadmin/SuperAdminRejectedApplications'
import TrafficRejectedPermissions from './pages/traffic-department/TrafficRejectedApplications'
import PwdRejectedPermissions from './pages/Pwddepartment/PwdRejectedApplications'
import NagarrachanaLogin from './pages/nagarrachana/NagarrachanaLogin'
import NagarrachanaContainer from './pages/containers/NagarrachanaContainer'
import NagarrachanaDashboard from './pages/nagarrachana/NagarrachanaDashboard'
import NagarrachanaTotalApplications from './pages/nagarrachana/NagarrachanaTotalApplications'
import SuperAdminPendingPermissions from './pages/superadmin/SuperAdminPendingApplications'
import TrafficPendingPermissions from './pages/traffic-department/TrafficPendingApplications'
import FirePendingPermissions from './pages/firedepartment/FirePendingApplications'
import PolicePendingPermissions from './pages/policeDeparment/PolicePendingApplications'
import NagarrachanaPendingAppl from './pages/nagarrachana/NagarrachanaPendingAppl'
import FireRejectedPermissions from './pages/firedepartment/FireRejectedApplications'
import PoliceRejectedApplications from './pages/policeDeparment/PoliceRejectedApplications'
import PwdPendingPermissions from './pages/Pwddepartment/PwdPendingApplications'
import NagarrachanaRejectedPermissions from './pages/nagarrachana/NagarrachanaRejectedApplications'
import NagarApprovedPermissions from './pages/nagarrachana/NagarApprovedApplications'
import FinalPermission from './pages/FinalPermission'
import Contact from './pages/contact/Contact'

const App = () => {
  return (
    <div>
      <ToastContainer {...ToastConfig} toastClassName="toast-homepage" />

      <Routes>
        <Route path="/" element={<HomepageContainer />}>
          <Route index element={<PermissionForm />} />
          <Route path="download" element={<DownLoad />} />
          <Route path="trackapplication" element={<TrackApplication />} />
          <Route path="murticarform" element={<MurtikarForm />} />
          <Route path="contact" element={<Contact />} />
        </Route>
        <Route path="/finalpermission/:id" element={<FinalPermission />} />

        <Route path='/super-admin' element={<SuperadminLogin />} />
        <Route path="/super-admin" element={<SuperAdminContainer />}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='pendingapplication' element={<SuperAdminPendingPermissions />} />
          <Route path='approvedapplications' element={<SuperAdminApprovedPermissions />} />
          <Route path='totalapplication' element={<SuperAdminTotalApplications />} />
          <Route path='rejectedapplication' element={<SuperAdminRejectedPermissions />} />
          <Route path='divisionreport' element={<DivisionWiseReport />} />
          <Route path='totalmurticapplications' element={<SuperAdminMurtiTotalAppl />} />

          <Route path='nagardashboard' element={<NagarrachanaDashboard role="Super Admin" />} />
          <Route path='totalnagar' element={<NagarrachanaTotalApplications role="Super Admin" />} />
          <Route path='approvednagar' element={<NagarApprovedPermissions role="Super Admin" />} />
          <Route path='pendingnagar' element={<NagarrachanaPendingAppl role="Super Admin" />} />
          <Route path='rejectednagar' element={<NagarrachanaRejectedPermissions role="Super Admin" />} />

          <Route path='divisiondashboard' element={<DivisionDashboard role="Super Admin" />} />
          <Route path='totaldivision' element={<DivisionTotalApplication role="Super Admin" />} />
          <Route path='approveddivision' element={<DivisionApprovedPermissions role="Super Admin" />} />
          <Route path='pendingdivision' element={<DivisionPendingPermissions role="Super Admin" />} />
          <Route path='rejecteddivision' element={<DivisionRejectedPermissions role="Super Admin" />} />

          <Route path='policedashboard' element={<PoliceDashoard role="Super Admin" />} />
          <Route path='totalpolice' element={<PoliceTotalApplications role="Super Admin" />} />
          <Route path='approvedpolice' element={<PoliceApprovedPermissions role="Super Admin" />} />
          <Route path='pendingpolice' element={<PolicePendingPermissions role="Super Admin" />} />
          <Route path='rejectedpolice' element={<PoliceRejectedApplications role="Super Admin" />} />

          <Route path='trafficdashboard' element={<TrafficDashboard role="Super Admin" />} />
          <Route path='totaltraffic' element={<TrafficTotalApplications role="Super Admin" />} />
          <Route path='approvedtraffic' element={<TrafficApprovedPermissions role="Super Admin" />} />
          <Route path='pendingtraffic' element={<TrafficPendingPermissions role="Super Admin" />} />
          <Route path='rejectedtraffic' element={<TrafficRejectedPermissions role="Super Admin" />} />

          <Route path='firedashboard' element={<FireDepartmentDashboard role="Super Admin" />} />
          <Route path='totalfire' element={<FireTotalApplications role="Super Admin" />} />
          <Route path='approvedfire' element={<FireApprovedPermissions role="Super Admin" />} />
          <Route path='pendingfire' element={<FirePendingPermissions role="Super Admin" />} />
          <Route path='rejectedfire' element={<FireRejectedPermissions role="Super Admin" />} />

          <Route path='pwddashboard' element={<PwdDashboard role="Super Admin" />} />
          <Route path='totalpwd' element={<PwdTotalApplications role="Super Admin" />} />
          <Route path='approvedpwd' element={<PwdApprovedPermissions role="Super Admin" />} />
          <Route path='pendingpwd' element={<PwdPendingPermissions role="Super Admin" />} />
          <Route path='rejectedpwd' element={<PwdRejectedPermissions role="Super Admin" />} />
        </Route>

        <Route path='/nagar-department' element={<NagarrachanaLogin />} />
        <Route path="/nagar-department" element={<NagarrachanaContainer />}>
          <Route path='dashboard' element={<NagarrachanaDashboard role="Nagar Department" />} />
          <Route path='approvedapplications' element={<NagarApprovedPermissions role="Nagar Department" />} />
          <Route path='totalapplication' element={<NagarrachanaTotalApplications role="Nagar Department" />} />
          <Route path='rejectedapplication' element={<NagarrachanaRejectedPermissions role="Nagar Department" />} />
          <Route path='pendingapplication' element={<NagarrachanaPendingAppl role="Nagar Department" />} />
        </Route>

        <Route path='/divisional-user' element={<DivisionalOfficerLogin />} />
        <Route path="/divisional-user" element={<DivisionalOfficerContainer />}>
          <Route path='dashboard' element={<DivisionDashboard role="Division Officer" />} />
          <Route path='totalapplication' element={<DivisionTotalApplication role="Division Officer" />} />
          <Route path='approvedapplications' element={<DivisionApprovedPermissions role="Division Officer" />} />
          <Route path='rejectedapplications' element={<DivisionRejectedPermissions role="Division Officer" />} />
          <Route path='pendingapplication' element={<DivisionPendingPermissions role="Division Officer" />} />
        </Route>

        <Route path='/traffic-police' element={<TrafficLogin />} />
        <Route path="/traffic-police" element={<TrafficPoliceContainer />}>
          <Route path='dashboard' element={<TrafficDashboard role="Traffic" />} />
          <Route path='totalapplication' element={<TrafficTotalApplications role="Traffic" />} />
          <Route path='approvedapplications' element={<TrafficApprovedPermissions role="Traffic" />} />
          <Route path='rejectedapplication' element={<TrafficRejectedPermissions role="Traffic" />} />
          <Route path='pendingapplication' element={<TrafficPendingPermissions role="Traffic" />} />
        </Route>

        <Route path='/fire-department' element={<FireDepartmentLogin />} />
        <Route path="/fire-department" element={<FireDepartmentContainer />}>
          <Route path='dashboard' element={<FireDepartmentDashboard role="Fire Department" />} />
          <Route path='totalapplication' element={<FireTotalApplications role="Fire Department" />} />
          <Route path='approvedapplications' element={<FireApprovedPermissions role="Fire Department" />} />
          <Route path='pendingapplication' element={<FirePendingPermissions role="Fire Department" />} />
          <Route path='rejectedapplication' element={<FireRejectedPermissions role="Fire Department" />} />
        </Route>

        <Route path='/police-department' element={<PoliceDepartmentLogin />} />
        <Route path="/police-department" element={<PoliceDepartmentContainer />}>
          <Route path='dashboard' element={<PoliceDashoard role="Police Department" />} />
          <Route path='approvedapplications' element={<PoliceApprovedPermissions role="Police Department" />} />
          <Route path='totalapplication' element={<PoliceTotalApplications role="Police Department" />} />
          <Route path='pendingapplication' element={<PolicePendingPermissions role="Police Department" />} />
          <Route path='rejectedapplication' element={<PoliceRejectedApplications role="Police Department" />} />
        </Route>

        <Route path='/pwd-department' element={<PwdDepartmentLogin />} />
        <Route path="/pwd-department" element={<PwdContainer />}>
          <Route path='dashboard' element={<PwdDashboard role="PWD Department" />} />
          <Route path='approvedapplications' element={<PwdApprovedPermissions role="PWD Department" />} />
          <Route path='totalapplication' element={<PwdTotalApplications role="PWD Department" />} />
          <Route path='rejectedapplication' element={<PwdRejectedPermissions role="PWD Department" />} />
          <Route path='pendingapplication' element={<PwdPendingPermissions role="PWD Department" />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
