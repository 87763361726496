export const loginFire = (session) => {
    return {
        type: "LOGIN_FIRE",
        payload: session,
    }
}
export const logoutFire = () => {
    return {
        type: "LOGOUT_FIRE",
    }
}
