import React, { useEffect, useState } from "react";
import { server } from "../../common";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useSelectAccess } from "../../store/stateFunctions";
import { FaListAlt } from "react-icons/fa";
import { CiClock1 } from "react-icons/ci";
import { MdOutlineDone } from "react-icons/md";

import { CgDanger } from "react-icons/cg";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState([]);
  const [navratri, setnavratri] = useState([]);
  const [shivjayanti, setShivjayanti] = useState([]);
  const [ajayanti, setajayanti] = useState([]);
  const [other, setOther] = useState([]);
  const [table, setTable] = useState([]);

  const user = useSelectAccess("Super Admin");

  useEffect(() => {
    getDashboard();
    getNavratriDashboard();
    getshivjayantiDashboard();
    getajayantiDashboard();
    getOther();
    getTable();
  }, []);

  const getDashboard = () => {
    server
      .get("/dashboard/ganeshutsav", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setDashboard(response.data);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getNavratriDashboard = () => {
    server
      .get("/dashboard/navratri", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setnavratri(response.data);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getshivjayantiDashboard = () => {
    server
      .get("/dashboard/shiv_jayanti", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setShivjayanti(response.data);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getajayantiDashboard = () => {
    server
      .get("/dashboard/ambedkarjayanti", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setajayanti(response.data);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getOther = () => {
    server
      .get("/dashboard/other", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setOther(response.data);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getTable = () => {
    server
      .get("/dashboard/dashboard", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        setTable(response.data.departments);
        // updateDashboardState("Super Admin", { dashboard2: response.data });
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="container my-5">
        <div className="container mt-4">
          <h3 className="my-3">Total Applications Status</h3>
          <table className="text-center table-bordered  table table-striped">
            <thead>
              <tr>
                <th>Department</th>
                <th>Approved</th>
                <th>Pending</th>
                <th>Rejected</th>
              </tr>
            </thead>
            <tbody>
              {table?.map((item) => {
                return (
                  <>
                    <tr>
                      <td className="fw-bold">
                        {item.name === "PWD" ? "MSEDCL" : item.name}
                      </td>
                      <td>{item.approved}</td>
                      <td>{item.pending}</td>
                      <td>{item.rejected}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <div className="card mb-3">
            <div className="card-header bg-primary text-white">
              Ganesh Utsav
            </div>
            <div className="card-body">
              <div className="row text-center">
                <div className="col">
                  <NavLink
                    // to="/super-admin/totalapplication"
                    className="text-decoration-none text-dark"
                  >
                    <FaListAlt className="fs-2" />
                    <h4 className="fs-2">{dashboard.totalAppl}</h4>
                    <p>Total Applications</p>
                  </NavLink>
                </div>

                <div className="col">
                  <CiClock1 className="fs-2" />
                  <h4 className="fs-2">{dashboard.pendingAppl}</h4>

                  <p>Pending Applications</p>
                </div>

                <div className="col">
                  <NavLink
                    // to="/super-admin/approvedapplications"
                    className="text-decoration-none text-dark"
                  >
                    <MdOutlineDone className="fs-2" />
                    <h4 className="fs-2">{dashboard.approvedAppl}</h4>
                    <p>Approved Applications</p>
                  </NavLink>
                </div>

                <div className="col">
                  <CgDanger className="fs-2" />
                  <h4 className="fs-2">{dashboard.rejectedAppl}</h4>
                  <p>Rejected Applications</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header bg-primary text-white">Navratri</div>
            <div className="card-body">
              <div className="row text-center">
                <div className="col">
                  <FaListAlt className="fs-2" />
                  <h4 className="fs-2">{navratri.totalAppl}</h4>
                  <p>Total Applications</p>
                </div>

                <div className="col">
                  <CiClock1 className="fs-2" />
                  <h4 className="fs-2">{navratri.pendingAppl}</h4>

                  <p>Pending Applications</p>
                </div>

                <div className="col">
                  <MdOutlineDone className="fs-2" />
                  <h4 className="fs-2">{navratri.approvedAppl}</h4>
                  <p>Approved Applications</p>
                </div>

                <div className="col">
                  <CgDanger className="fs-2" />
                  <h4 className="fs-2">{navratri.rejectedAppl}</h4>
                  <p>Rejected Applications</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header bg-primary text-white">
              Shiv Jayanti
            </div>
            <div className="card-body">
              <div className="row text-center">
                <div className="col">
                  <FaListAlt className="fs-2" />
                  <h4 className="fs-2">{shivjayanti.totalAppl}</h4>
                  <p>Total Applications</p>
                </div>

                <div className="col">
                  <CiClock1 className="fs-2" />
                  <h4 className="fs-2">{shivjayanti.pendingAppl}</h4>

                  <p>Pending Applications</p>
                </div>

                <div className="col">
                  <MdOutlineDone className="fs-2" />
                  <h4 className="fs-2">{shivjayanti.approvedAppl}</h4>
                  <p>Approved Applications</p>
                </div>

                <div className="col">
                  <CgDanger className="fs-2" />
                  <h4 className="fs-2">{shivjayanti.rejectedAppl}</h4>
                  <p>Rejected Applications</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header bg-primary text-white">
              Ambedkar Jayanti
            </div>
            <div className="card-body">
              <div className="row text-center">
                <div className="col">
                  <FaListAlt className="fs-2" />
                  <h4 className="fs-2">{ajayanti.totalAppl}</h4>
                  <p>Total Applications</p>
                </div>

                <div className="col">
                  <CiClock1 className="fs-2" />
                  <h4 className="fs-2">{ajayanti.pendingAppl}</h4>

                  <p>Pending Applications</p>
                </div>

                <div className="col">
                  <MdOutlineDone className="fs-2" />
                  <h4 className="fs-2">{ajayanti.approvedAppl}</h4>
                  <p>Approved Applications</p>
                </div>

                <div className="col">
                  <CgDanger className="fs-2" />
                  <h4 className="fs-2">{ajayanti.rejectedAppl}</h4>
                  <p>Rejected Applications</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-3">
            <div className="card-header bg-primary text-white">Other</div>
            <div className="card-body">
              <div className="row text-center">
                <div className="col">
                  <FaListAlt className="fs-2" />
                  <h4 className="fs-2">{other.totalAppl}</h4>
                  <p>Total Applications</p>
                </div>

                <div className="col">
                  <CiClock1 className="fs-2" />
                  <h4 className="fs-2">{other.pendingAppl}</h4>

                  <p>Pending Applications</p>
                </div>

                <div className="col">
                  <MdOutlineDone className="fs-2" />
                  <h4 className="fs-2">{other.approvedAppl}</h4>
                  <p>Approved Applications</p>
                </div>

                <div className="col">
                  <CgDanger className="fs-2" />
                  <h4 className="fs-2">{other.rejectedAppl}</h4>
                  <p>Rejected Applications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
