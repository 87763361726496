import store from "."
import {
    logoutFire,
    logoutPolice,
    logoutPwdUser,
    logoutSuperAdmin,
    logoutTraffic,
    logoutdiviofficeUser,
    logoutnagarUser
} from "./actions"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

export function updateDashboardState(role, data) {
    switch (role) {
        // case "Student":
        //     store.dispatch(setStudentDashboard(data));
        //     break;
        default:
            break;
    }
}

export function useSelectAccess(role) {
    return useSelector((store) => {
        switch (role) {
            case "Super Admin":
                return store.superadmin
            case "Traffic":
                return store.traffic
            case "Division Officer":
                return store.divisionofficer
            case "Police Department":
                return store.police
            case "Fire Department":
                return store.fireuser
            case "PWD Department":
                return store.pwduser
            case "Nagar Department":
                return store.nagaruser
            default:
                return null;
        }
    })
}

export function logoutAccess(role) {
    switch (role) {
        case "Super Admin":
            store.dispatch(logoutSuperAdmin());
            break;
        case "Division Officer":
            store.dispatch(logoutdiviofficeUser());
            break;
        case "Police Department":
            store.dispatch(logoutPolice());
            break;
        case "Traffic":
            store.dispatch(logoutTraffic());
            break;
        case "Fire Department":
            store.dispatch(logoutFire());
            break;
        case "PWD Department":
            store.dispatch(logoutPwdUser());
            break;
        case "Nagar Department":
            store.dispatch(logoutnagarUser());
            break;
        default:
            return;
    }
    toast.info("Logout successful", { toastId: "LogoutReplacable" });
} 
