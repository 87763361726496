export const loginnagarUser = (session) => {
    return {
        type: "LOGIN_NAGAR_USER",
        payload: session,
    };
};

export const logoutnagarUser = () => {
    return {
        type: "LOGOUT_NAGAR_USER",
    };
};
