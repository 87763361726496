import React from "react";
import Sidebar from "../../pages/divisionalofficer/DivisionaOfficeSidebar";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import TrafficPoliceSidebar from "../traffic-department/TrafficPoliceSidebar";
// import { Outlet } from "react-router-dom";

const TrafficPoliceContainer = () => {
  document.title = "Traffic Police";
  const traffic = useSelector((store) => store.traffic);

  return (
    <>
      <div className="row">
        <TrafficPoliceSidebar role="Traffic" />
        <div className="container">
          {traffic ? <Outlet /> : <Navigate to="/traffic-police" />}
          {/* <Outlet /> */}
        </div>
      </div>
      {/* <div className="row ">
          <div className="col-md-3">
            <Sidebar role="Super Admin" />
          </div>
          <div className="col-md-9">
            <div className="container">
              {superadmin ? <Outlet /> : <Navigate to="/superadmin" />}
            </div>
          </div>
        </div> */}
    </>
  );
};

export default TrafficPoliceContainer;
