export const loginPwdUser = (session) => {
    return {
        type: "LOGIN_PWD",
        payload: session,
    };
};

export const logoutPwdUser = () => {
    return {
        type: "LOGOUT_PWD",
    };
};
