
// const getStudentFromLocalStorage = () => {
//   try {
//     const studentJSON = getStudentTokenFromLocal();
//     if (studentJSON) {
//       const student = JSON.parse(studentJSON);
//       if (student.authToken && student.loggedInTime) {
//         return student;
//       } else {
//         removeStudentTokenFromLocal();
//         return null;
//       }
//     }
//   } catch (e) {
//     removeStudentTokenFromLocal();
//     return null;
//   }
// };

import { removeFireUserToken } from "../../common";
import { fireTokenName } from "../../config";

const getPoliceFromLocalStorage = () => {
    try {
        const fireuser = JSON.parse(sessionStorage.getItem(fireTokenName));
        if (fireuser && fireuser.authToken && fireuser.loggedInTime) {
            return fireuser
        } else {
            removeFireUserToken();
            return null
        }
    } catch (error) {
        removeFireUserToken()
        return null
    }
}

const getInitial = () => {
    return getPoliceFromLocalStorage();
};

const fireDepartmentReducer = (state = getInitial(), action) => {
    switch (action.type) {
        case "LOGIN_FIRE":
            const { loggedInTime, authToken } = action.payload;
            const newState = {
                loggedInTime: loggedInTime || Date.now(),
                authToken: authToken,
            };
            sessionStorage.setItem(fireTokenName, JSON.stringify(newState));
            return newState;
        case "LOGOUT_FIRE":
            removeFireUserToken();
            return null;
        default:
            return state;
    }
};

export default fireDepartmentReducer;