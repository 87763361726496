export const BACKEND_SERVER_URL = process.env.REACT_APP_BE_URL;
export const PAYMENT_KEY = process.env.REACT_APP_PAYMENT_KEY
// export const backend = "https://mernbackend-jyf4.onrender.com/api"

export const superAdminTokenName = "supATH";
export const ngrrachanaTokenName = "ngrATH";
export const DiviOfficeUserTokenName = "diviATH";
export const trafficUserTokenName = "trafficATH";
export const policeTokenName = "policeATH";
export const fireTokenName = "fireATH";
export const pwdTokenName = "pwdATH";

export const shareurl = process.env.SHARE_URL;

export const ToastConfig = {
  position: "bottom-right",
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
  // theme: "colored",
  limit: 3,
  bodyClassName: "toastContainerBody",
};
