export const logindiviofficeUser = (session) => {
    return {
        type: "LOGIN_DIVISIONAL_USER",
        payload: session,
    };
};

export const logoutdiviofficeUser = () => {
    return {
        type: "LOGOUT_DIVISIONAL_USER",
    };
};
